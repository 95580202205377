import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReportView from "./ReportView";
import WhatsNew from "./WhatsNew";
import RecentNotification from "./RecentNotification";
import LatestNewsletter from "./LatestNewsletter";
import Admin from "./Admin";
import RightPanel from "./RightPanel";

interface props {
  role: string;
  tab: string;
  openDialogue: boolean;
  setEditing: (value: boolean) => void;
  handleOpenDialog: (value: boolean) => void;
  adminRef: React.RefObject<HTMLDivElement>;
  isPowerUser: boolean;
}
const AppRouter: React.FC<props> = ({
  role,
  tab,
  openDialogue,
  setEditing,
  handleOpenDialog,
  adminRef,
  isPowerUser,
}) => {
  // const [userRole,setUserRole] = useState<string>("")
  useEffect(() => {
    // setUserRole(role)
    // console.log('Role',role)
  }, [role]);
  return (
    <Routes>
      <Route
        path="/view"
        element={
          <ReportView
            trigger={tab}
            isPowerUser={isPowerUser}
            openDialog={openDialogue}
          />
        }
      />
      <Route path="/Pages/WhatsNew" element={<WhatsNew />} />
      <Route path="/Pages/Notifications" element={<RecentNotification />} />
      <Route path="/" element={<Navigate to="/view" />} />
      <Route path="/Pages/Newsletters" element={<LatestNewsletter />} />
      <Route path="/Pages/Training" element={<RightPanel />} />

      <Route
        path="/admin"
        element={
          role === "Admin" || role === "SuperAdmin" ? (
            <Admin adminRef={adminRef} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      {/* redirect all other routes to /*/}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;
