import axios from "axios";

const protectedAxiosInstance = axios.create();

protectedAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = `${window.location.origin}/api/service/login?uri_memory=${window.location.pathname + window.location.search}`;
    }
    return Promise.reject(error);
  }
);

export default protectedAxiosInstance