import React, { useEffect, useState } from "react";
import axios from "axios";
import loadingImg from "../Images/Loading.gif";
import { Link } from "@fluentui/react-components";
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, IDetailsListStyles, SelectionMode } from "@fluentui/react";
import { ReportDetails } from "./AdminReportCatalog";
import { useNavigate } from "react-router-dom";
import protectedAxiosInstance from "./helpers/api";
import { IDetailsColumnStyles } from "@fluentui/react/lib-commonjs/DetailsList";


function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice().sort((a, b) => {
    if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
    if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
    return 0;
  });
}

const ReportCatalog = () => {
  const navigate = useNavigate();
  const [originalCatalog, setOriginalCatalog] = useState<ReportDetails[]>([]);
  const [displayedCatalog, setDisplayedCatalog] = useState<ReportDetails[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [sortedColumnKey, setSortedColumnKey] = useState<string>("");
  const [isSortedDescending, setIsSortedDescending] = useState<boolean>(false);

  const searchCatalog = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setDisplayedCatalog(
      originalCatalog.filter((report: ReportDetails) =>
        searchTerm.length === 0 ||
        report.TileText.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.Description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.Persona.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.ContactEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.IsPowerBI.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const getURL = (reportUrl: string): string => {
    let url = reportUrl;
    if (reportUrl.includes("ftbi.microsoft.com") || reportUrl.includes("ftbiuat.microsoft.com")) {
      let reportId = reportUrl.split("=")[1];
      let parent;
      if (reportId.includes("_")) {
        parent = reportId.split("_")[0];
        url = "/view?parent=" + parent + "&tileId=" + reportId;
      } else {
        url = "/view?parent=" + reportId;
      }
    }
    return url;
  };

  const LoadData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await protectedAxiosInstance.get(
        `${window.location.origin}/api/Service/GetReportLibraryConfiguration`,
        {
          withCredentials: true,
        }
      );
      setOriginalCatalog(response.data.Table);
      setDisplayedCatalog(response.data.Table);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  const onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const newIsSortedDescending = sortedColumnKey === column.key ? !isSortedDescending : false;
    const sortedItems = _copyAndSort(displayedCatalog, column.fieldName!, newIsSortedDescending);

    setSortedColumnKey(column.key);
    setIsSortedDescending(newIsSortedDescending);
    setDisplayedCatalog(sortedItems);
  };

  const columnHeaderStyles: Partial<IDetailsColumnStyles> = {
     sortIcon: {
      color: 'white',
    },
  };

  const columns: IColumn[] = [
    {
      key: "persona",
      name: "Persona",
      fieldName: "Persona",
      minWidth: 130,
      maxWidth: 190,
      isResizable: true,
      styles: columnHeaderStyles,
      showSortIconWhenUnsorted: true,
      isSorted: sortedColumnKey === "persona",
      isSortedDescending: isSortedDescending,
      onColumnClick: onColumnClick,
      onRender: (item: ReportDetails) => (
        <div className="text-cell text-cell-center">{item.Persona}</div>
      ),
    },
    {
      key: "title",
      name: "Title",
      fieldName: "TileText",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      styles: columnHeaderStyles,
      showSortIconWhenUnsorted: true,
      isSorted: sortedColumnKey === "title",
      isSortedDescending: isSortedDescending,
      onColumnClick: onColumnClick,
      onRender: (item: ReportDetails) => (
        <a
          onClick={() => navigate(getURL(item.ReportURL))}
          target="_self"
          className="text-cell text-cell-center"
        >
          {item.TileText}
        </a>
      ),
    },
    {
      key: "description",
      name: "Description",
      fieldName: "Description",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      styles: columnHeaderStyles,
      showSortIconWhenUnsorted: true,
      isSorted: sortedColumnKey === "description",
      isSortedDescending: isSortedDescending,
      onColumnClick: onColumnClick,
      onRender: (item: ReportDetails) => (
        <div className="text-cell text-cell-center">{item.Description}</div>
      ),
    },
    {
      key: "contact",
      name: "Contact",
      fieldName: "ContactEmail",
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      styles: columnHeaderStyles,
      showSortIconWhenUnsorted: true,
      isSorted: sortedColumnKey === "contact",
      isSortedDescending: isSortedDescending,
      onColumnClick: onColumnClick,
      onRender: (item: ReportDetails) => (
        <div className="text-cell text-cell-center">{item.ContactEmail}</div>
      ),
    },
    {
      key: "videos",
      name: "Videos",
      fieldName: "Videos",
      minWidth: 120,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: ReportDetails) =>
        item.Videos && item.Videos.length > 0 ? (
          <td className="tableRowContactData text-cell text-cell-center">
            {item.Videos.map((video: any, idx: number) => {
              return (
                <>
                  <Link
                    href={video.VideoURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {video.VideoTitle}
                  </Link>
                  {idx < item.Videos.length - 1 && ","}&nbsp;
                </>
              );
            })}
          </td>
        ) : (
          <td className="tableRowContactData text-cell text-cell-center">
            <span>No Video(s)</span>
          </td>
        ),
    },
  ];

  const detailsListStyles: Partial<IDetailsListStyles> = {
    headerWrapper: {
      marginTop: "-12px",
      selectors: {
        ".ms-DetailsHeader": {
          backgroundColor: "#505050",
        },
        ".ms-DetailsHeader-cellName": {
          color: "white",
        },
        ".ms-DetailsHeader-cell:hover": {
          backgroundColor: "#505050",
        },
      },
    },
  };

  return (
    <div className="ReportContainer" style={{ padding: "0px 100px", height: "68%" }}>
      <h3 className="GroupTitle">Report Catalog</h3>
      {isLoading && (
        <div className="LoadingReports">
          <center>
            <img className="loadingimg" src={loadingImg} alt="Loading" />
          </center>
        </div>
      )}
      {isError && (
        <div className="ErrorReports">
          <center>
            <h2>:(</h2>
            <h4>
              Something Went Wrong,{" "}
              <span className="TryAgainLink" onClick={() => LoadData()}>
                Try Again
              </span>
            </h4>
          </center>
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <div className="inputContainer catalogContainer">
            <input
              type="text"
              placeholder="Search Reports by Title, Description, or Contact"
              aria-label="Search Reports by Title, Description, or Contact"
              value={searchTerm}
              onChange={(e) => searchCatalog(e.target.value)}
              className="SearchBoxInput SearchBoxInputBox CatalogSearchbox"
              style={{ width: "101%" }}
            />
            <div
              className="searchMagnifierIcon"
              id="CatalogSearchIcon"
              title="Search Partners"
            ></div>
          </div>
          <div style={{ marginTop: "40px", height: "100%", overflow: "auto" }}>
            <DetailsList
              items={displayedCatalog}
              columns={columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              styles={detailsListStyles}
              constrainMode={ConstrainMode.unconstrained}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ReportCatalog;