import React, { useEffect, useState } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  TextField,
  IDetailsListStyles
} from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { AddSquare16Regular, Delete16Regular } from "@fluentui/react-icons";
import Loading from "../Images/Loading.gif";
import "../Styles/PowerUsers.css";
import protectedAxiosInstance from "./helpers/api";

interface AdminPowerUsersProps {
  editModeTab: string;
  setEditMode: (value: string) => void;
}

interface PowerUserData {
  username: string;
  actions?: string;
}

const AdminPowerUsers: React.FC<AdminPowerUsersProps> = ({
  editModeTab,
  setEditMode,
}) => {
  const [powerUserData, setPowerUserData] = useState<PowerUserData[]>([]);
  const [editableRow, setEditableRow] = useState<PowerUserData | null>(null);
  const [deletedRows, setDeletedRows] = useState<string[]>([]);
  const [showSaveCancel, setShowSaveCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAllPowerUsers = async () => {
      try {
        const response = await protectedAxiosInstance.get(
          "/api/Service/GetPowerUsers"
        );
        const users = response.data.map((user: any) => ({
          username: user.userName,
        }));
        setPowerUserData(users);
      } catch (error) {
        console.error("Error fetching power users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllPowerUsers();
  }, []);

  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={Loading} alt="Loading..." className="loader" />
      </div>
    );
  }

  const addUser = () => {
    if (!editableRow) {
      setEditableRow({ username: "" });
      setShowSaveCancel(true);
    }
  };

  const saveUser = async () => {
    try {
      if (editableRow) {
        if (!editableRow.username.trim()) {
          alert("Username cannot be empty.");
          return;
        }
        const newUser = {
          userName: editableRow.username,
          accessRole: "PowerUser",
        };
        await protectedAxiosInstance.post("/api/Service/AddPowerUser", newUser);
        setPowerUserData([editableRow, ...powerUserData]);
        setEditableRow(null);
      }

      for (const username of deletedRows) {
        await protectedAxiosInstance.delete(`/api/Service/DeletePowerUser`, {
          params: { userName: username },
        });
      }

      setDeletedRows([]);
      setShowSaveCancel(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const cancelEdit = () => {
    setEditableRow(null);
    setPowerUserData([
      ...powerUserData,
      ...deletedRows.map((u) => ({ username: u })),
    ]);
    setDeletedRows([]);
    setShowSaveCancel(false);
  };

  const deleteUser = (username: string) => {
    setDeletedRows([...deletedRows, username]);
    setPowerUserData(
      powerUserData.filter((user) => user.username !== username)
    );
    setShowSaveCancel(true);
  };

    const detailsListStyles: Partial<IDetailsListStyles> = {
      headerWrapper: {
        marginTop: "-12px",
        selectors: {
          ".ms-DetailsHeader": {
            backgroundColor: "#505050",
          },
          ".ms-DetailsHeader-cellName": {
            color: "white",
          },
          ".ms-DetailsHeader-cell:hover": {
            backgroundColor: "#505050",
          },
        },
      },
    };

  const columns: IColumn[] = [
    {
      key: "username",
      name: "Username",
      fieldName: "username",
      minWidth: 100,
      maxWidth: 550,
      isResizable: true,
      onRender: (item: PowerUserData) =>
        editableRow && item.username === editableRow.username ? (
          <TextField
            value={editableRow["username"]}
            onChange={(e, newValue) => {
              setEditableRow({ ...editableRow, username: newValue || "" });
            }}
          />
        ) : (
            <span>{item.username}</span>
          ),
        },
        {
          key: "actions",
          name: "Actions",
          minWidth: 100,
          maxWidth: 300,
          isResizable: true,
          onRender: (item: PowerUserData) => (
        <div
          className="actions"
          style={{ display: "flex", marginLeft: "-18px" }}
        >
          <Button
            onClick={() => deleteUser(item.username)}
            title="Delete this User"
            icon={<Delete16Regular />}
            appearance="subtle"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div 
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <h1>FTBI Power BI Editors</h1>
        <Button
          className="addPowerUserButton"
          onClick={addUser}
          title="Add Power User"
          icon={<AddSquare16Regular />}
          appearance="subtle"
          style={{ marginTop: "11px" }}
        >
          Add Power BI Editors
        </Button>
      </div>
      <div className="ExternalReportContainer" style={{ height: "60vh" }}>
        <DetailsList
          items={editableRow ? [editableRow, ...powerUserData] : powerUserData}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          isHeaderVisible={true}
          styles={detailsListStyles}
        />
      </div>
      {showSaveCancel && (
        <div
          className="saveCancelButtons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            onClick={saveUser}
            appearance="primary"
            style={{ marginRight: "10px" }}
            disabled={!editableRow?.username && deletedRows.length === 0}
          >
            Save
          </Button>
          <Button onClick={cancelEdit} appearance="secondary">
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default AdminPowerUsers;
