import { ToggleButton } from "@fluentui/react-components";
import { Edit20Filled as EditIcon } from "@fluentui/react-icons";
import * as powerbi from "powerbi-client";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DATAMODELicon from "../Images/img/DATAMODELicon.png";
import "../Styles/tabnavigation.css";
import DataModel from "./DataModel";
import OCPLandingV2 from "./OCPLandingV2/OCPLandingV2";
import ReportCatalog from "./ReportCatalog";
import ReportCopilot from "./ReportCopilot/ReportCopilot";
import { isTokenExpiry, signOut } from "./helpers/Common";
import { Constants } from "./helpers/Constants";
import protectedAxiosInstance from "./helpers/api";

interface props {
  trigger: string;
  openDialog: boolean;
  isPowerUser: boolean;
}

const ReportView: React.FC<props> = ({ trigger, openDialog, isPowerUser }) => {
  const env: string =
    window.location.host.includes("dev") ||
    window.location.host.includes("localhost")
      ? "dev"
      : "non-dev";
  let workspaceId: string;
  if (
    window.location.host.includes("dev") ||
    window.location.host.includes("localhost")
  ) {
    workspaceId = "c7300506-4e4b-47d2-8d0f-bfdfd6897ec1";
  } else if (window.location.host.includes("uat")) {
    workspaceId = "9a95ad97-5af4-4412-8c23-17d789e1170d";
  } else {
    workspaceId = "9a95ad97-5af4-4412-8c23-17d789e1170d";
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const [showView, setShowView] = useState<boolean>(false);
  const [navList, setNavList] = useState<any>([]);
  const [currentTile, setCurrentTile] = useState<string>("");
  const reportClass = "report-container";
  const [showOCPLanding, setShowOCPLanding] = useState(true);
  const [showReportCatalog, setShowReportCatalog] = useState(false);
  const [selectedTile, setSelectedTile] = useState<string>();
  const [powerBIConfig, setPowerBIConfig] = useState<any>();
  const [embedConfig, setEmbedConfig] = useState<any>();
  const [showReport, setShowReport] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [report, setReport] = useState<powerbi.Report>();
  const [showSaveAsDialog, setShowSaveAsDialog] = useState<boolean>(false);
  const [isCopilotOpen, setIsCopilotOpen] = useState<boolean>(false);
  const [isReportLoaded, setIsReportLoaded] = useState<boolean>(false);
  const [tileLoading, setTileLoading] = useState<string | null>(null);
  const [isDataModelOpen, setIsDataModelOpen] = useState(false); // New state for Data Model toggle
  const [isReload, setIsReload] = useState<boolean>(true);

  const loadPowerBiConfigSuccess = (response: any) => {
    const tmpObj = {} as any;
    if (response) {
      for (const container in response.Table) {
        tmpObj[response.Table[container]["ContainerID"]] = {
          PowerBIURL: response.Table[container]["PowerBIURL"],
          Page: response.Table[container]["SectionName"],
          FilterTable: response.Table[container]["FilterTable"],
          FilterColumn: response.Table[container]["FilterColumn"],
          FilterType: response.Table[container]["FilterType"],
          PublishTag: response.Table[container]["PublishTag"],
        };
      }
    }
    return tmpObj;
  };

  const changeSearchParams = (tileId: string) => {
    const search = new URLSearchParams(window.location.search);
    const existingTileId = search.get("tileId");
    const existingParent = search.get("parent");
    if (existingTileId !== null && existingTileId !== tileId) {
      search.set("tileId", tileId);
      setSearchParams(search);
    }

    // setSearchParams(searchParams)
  };
  const getEmbedConfig = (tab: string, tileId?: string, tmpConfig?: any) => {
    const accessToken = localStorage.getItem("powerbi_access_token") || "";
    // check token in local storage and if it is expired, logout and log back in. This will regerate the token.
    if (isTokenExpiry()) {
      signOut(`${window.location.pathname + window.location.search}`);
    }
    let tmpEmbedConfig;
    if (!tileId) {
      tileId = tab;
    }
    tmpEmbedConfig = {
      type: "report",
      accessToken: accessToken,
      pageName:
        tmpConfig[tileId!].Page.length !== 0 ? tmpConfig[tileId!].Page : null,
      embedUrl: tmpConfig[tileId!].PowerBIURL,
      settings: {
        navContentPaneEnabled: false,
        filterPaneEnabled: true,
        persistentFiltersEnabled: true,
        personalBookmarksEnabled: true,
        useCustomSaveAsDialog: true,
        bars: {
          actionBar: {
            visible: true,
          },
        },
      },
    };
    return tmpEmbedConfig;
  };

  const handleEdit = async () => {
    if (edit){
      // Reloading the report discards changes and exits edit mode
      // Note: Not using .switchMode() because it does not discard the change
      report?.reload(); 
    } else {
      await report?.switchMode(models.ViewMode.Edit);
    }
    setEdit(!edit);
  };

  useEffect(() => {
    if (isReportLoaded && powerBIConfig[selectedTile!]) {
      report?.setPage(powerBIConfig[selectedTile!]?.Page);
    }
  }, [isReportLoaded]);

  const setEmbeddedConfiguration = async (tileId: string, idx: number) => {
    setIsDataModelOpen(false);
    setShowReport(true);
    setTileLoading(tileId);
    try {
      if (powerBIConfig[tileId!]?.PowerBIURL != embedConfig?.embedUrl) {
        setEmbedConfig({
          ...embedConfig,
          embedUrl: powerBIConfig[tileId!].PowerBIURL,
          pageName: powerBIConfig[tileId!].Page,
        });
      } else if (isReportLoaded) {
        await report?.setPage(powerBIConfig[tileId!].Page);
      } else {
        // if the page change is initated before the report is loaded,
        // the page change will be done in the isReportLoaded useEffect
        // see above useEffect
      }

      setTileLoading(null);
      changeSearchParams(tileId);
      setSelectedTile(tileId);
    } catch (error) {
      setTileLoading(null);
      console.error(error);
    }
  };

  const setInitialParameters = (navlist: any) => {
    if (navlist) {
      const tileId = navlist[0].tileId;
      const search = new URLSearchParams(window.location.search);
      //search.set("tileId", tileId)
      searchParams.set("tileId", tileId);
      //setSearchParams(search)
    }
  };

  const setData = async (tmpConfig: any, tab: string, tile?: string) => {
    let defaultTileId;
    let horizontalNavList;

    try {
      const response = await protectedAxiosInstance.get(
        "/api/service/GetHorizontalTileList",
        {
          withCredentials: true,
        }
      );
      horizontalNavList = JSON.parse(
        JSON.parse(response.data.Table[0].ListData)
      );
      const navlist = horizontalNavList[tab || ""];
      setNavList(navlist);
      setPowerBIConfig(tmpConfig);

      if (tile != undefined) {
        defaultTileId = tile;
        setSelectedTile(defaultTileId);
      } else {
        defaultTileId = navlist && navlist.length > 0 ? navlist[0].tileId : tab;
        if (navlist && navlist.length > 0) setSelectedTile(navlist[0].tileId);
        setInitialParameters(navlist);
      }
      setEmbedConfig(getEmbedConfig(tab, defaultTileId, tmpConfig));
      setShowReport(true);
    } catch (error) {
      console.error("Error", error);
    }
    // setHorizontalNavList(tab)
  };

  const getUpdateReportConfiguration = (tileId: string) => {
    let tileText: string = "";
    let url = window.location.host;
    // do all this in one foreach and when you get the tiletext break the loop
    // get tiletext for this tileId from Constants.NAV_TILE_LIST
    const navlist =
      url.includes("prod") || url.includes("ftbi.microsoft.com")
        ? Constants.NAV_TILE_LIST.home
        : Constants.NAV_TILE_LIST_NON_PROD.home;
    navlist.forEach((nav: any) => {
      if (nav.tileId === tileId) {
        tileText = nav.tileText;
      }
      if (nav.hasSubPages) {
        nav.subPages.forEach((sub: any) => {
          if (sub.tileId === tileId) {
            tileText = sub.tileText;
          }
          if (sub.hasSubPages) {
            sub.subPages.forEach((subsub: any) => {
              if (subsub.tileId === tileId) {
                tileText = subsub.tileText;
              }
            });
          }
        });
      }
    });
    return tileText;
  };
  const updateRecentReports = async (value: string) => {
    // let currentReports = AppDataFactory().getRecentReports();
    let newReport = value;
    let payload;
    let toBeUpdatedReports = "";
    const idToken = localStorage.getItem("id_token");
    try {
      let currentReports = await protectedAxiosInstance.get(
        "api/service/GetRecentReports",
        {
          withCredentials: true,
        }
      );
      let ReportString =
        currentReports.data.Table.length > 0
          ? currentReports.data.Table[0].RecentReports
          : "";
      //let ReportString = "";
      if (ReportString.length > 0) {
        let reports: string[] = ReportString.split("$|$");
        if (ReportString.indexOf(newReport) >= 0) {
          reports.splice(reports.indexOf(newReport), 1);
          reports.unshift(newReport);
          toBeUpdatedReports = reports.join("$|$");
        } else if (reports.length >= 10) {
          reports = reports.slice(0, 9);
          reports.unshift(newReport);
          toBeUpdatedReports = reports.join("$|$");
        } else {
          toBeUpdatedReports = newReport + "$|$" + ReportString;
        }
      } else {
        toBeUpdatedReports = newReport;
      }

      const user = localStorage.getItem("user");
      const jsonData = JSON.parse(user || "");
      payload = {
        UserAlias: jsonData.mail,
        Reports: toBeUpdatedReports,
      };
      try {
        await protectedAxiosInstance.post(
          "api/Service/UpdateRecentReports",
          payload,
          {
            withCredentials: true,
          }
        );
      } catch (error) {
        console.error("Error while updating recent reports", error);
      }
    } catch (error) {
      console.error("Error while getting recent reports", error);
    }
  };

  const fetchNSetData = (tab: string, tile: string) => {
    const jsonResponse = localStorage.getItem("powerbi_config")
      ? JSON.parse(localStorage.getItem("powerbi_config")!)
      : null;
    const tmpConfig = loadPowerBiConfigSuccess(jsonResponse);
    if (tile != null) {
      setData(tmpConfig, tab!, tile);
    } else {
      setData(tmpConfig, tab!);
    }
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const tab = search.get("parent");
    const tile = search.get("tileId");
    const prevSearch = new URLSearchParams(
      sessionStorage.getItem("previousPage") || ""
    );
    // setIsReportLoaded(false);
    setEdit(false);

    if (!search.size) {
      setShowReport(false);
      setShowReportCatalog(false);
      setShowOCPLanding(true);
    } else if (tab == "ReportCatalog_SearchReport") {
      setShowOCPLanding(false);
      setShowReport(false);
      setShowReportCatalog(true);
    } else if (
      isReload ||
      !prevSearch.size ||
      prevSearch.get("parent") !== tab
    ) {
      setShowOCPLanding(false);
      setShowReportCatalog(false);
      setIsDataModelOpen(false);

      fetchNSetData(tab!, tile!);
      setCurrentTile(tab!);

      // setShowReport(true); // Commenting it out. This should be logically correct but it is causing the report to break.
      // Another setShowReport is added in fetchNSetData > setData function which is working fine.
      setIsReportLoaded(false);

      // getRecentReports(); // This is not used anywhere
      const tiletext = getUpdateReportConfiguration(tab!);
      setTimeout(() => {
        updateRecentReports(tab + "#|#" + tiletext);
      }, 2000);
    }

    sessionStorage.setItem("previousPage", window.location.search);
    setIsReload(false);
    // return () => {
    //   setShowReport(false);
    // };
  }, [trigger, window.location.search]);

  return (
    <>
      {showOCPLanding ? (
        <OCPLandingV2 />
      ) : showReportCatalog ? (
        <ReportCatalog />
      ) : (
        <div className="Reportingsection" id="EmbeddReportCotainer">
          <div className="fourthLevelnavigation">
            <ul
              className="tabLevelul"
              style={{ borderBottom: "2px grey", flex: 1 }}
            >
              {navList &&
                navList.map((nav: any, idx: number) => (
                  <li
                    key={idx}
                    className={`tabLevelli ${
                      selectedTile === nav.tileId && !isDataModelOpen
                        ? "selectedTabLevelli"
                        : ""
                    }${tileLoading === nav.tileId ? "loadingTabLevelli" : ""}`}
                    onClick={async () =>
                      await setEmbeddedConfiguration(nav.tileId, idx)
                    }
                  >
                    {nav.tileText}
                  </li>
                ))}
            </ul>

            <div style={{ display: "flex", alignItems: "center", gap: "5px", padding: "7px 10px" }}>
              {env === "dev" && (
                <ToggleButton
                  appearance="subtle"
                  checked={isCopilotOpen}
                  onClick={() => setIsCopilotOpen(!isCopilotOpen)}
                  icon={
                    <img src="https://res-2-sdf.cdn.office.net/files/fabric-cdn-prod_20240411.001/assets/brand-icons/product/svg/copilot_24x1.svg"></img>
                  }
                >
                  Copilot
                </ToggleButton>
              )}

              <ToggleButton
                appearance="subtle"
                checked={isDataModelOpen}
                onClick={() => {
                  setIsReportLoaded(false);
                  setShowReport(!showReport);
                  setIsDataModelOpen(!isDataModelOpen);
                }}
                className={isDataModelOpen ? "selectedDataModel" : ""}
                icon={
                  <img
                    src={DATAMODELicon}
                    alt="Data Model Icon"
                    style={{ height: "20px", width: "20px" }}
                  />
                }
              >
                Data Model
              </ToggleButton>

              {isPowerUser && (
                <ToggleButton
                  appearance="subtle"
                  checked={edit}
                  onClick={handleEdit}
                  // className={isDataModelOpen ? "selectedDataModel" : ""}
                  icon={<EditIcon />}
                  style={{ minWidth: "0px" }}
                >
                  {edit ? "View mode" : "Edit"}
                </ToggleButton>
              )}
            </div>
          </div>
          <hr className="tabseparator" id="tabalign" />
          <div>
            <div className="OCPScorecardContainer">
              <div className="ReportViewSection">
                {/* Toggle between DataModel and PowerBIEmbed components */}
                {isDataModelOpen && embedConfig ? (
                  <DataModel
                    reportId={
                      new URL(embedConfig.embedUrl).searchParams.get(
                        "reportId"
                      ) || ""
                    }
                    workspaceId={workspaceId}
                  />
                ) : (
                  <div className="ReportContainer" style={{ display: "flex" }}>
                    <div
                      className="ReportViewSectionContent HorizontalTab"
                      style={{ flex: "1" }}
                    >
                      {showReport && embedConfig && (
                        <PowerBIEmbed
                          eventHandlers={
                            new Map([
                              [
                                "loaded",
                                function () {
                                  setIsReportLoaded(true);
                                },
                              ],
                              [
                                "error",
                                function (event) {
                                  console.log("PBI Error: ", event?.detail);
                                },
                              ],
                            ])
                          }
                          embedConfig={{
                            ...embedConfig,
                            permissions: powerbi.models.Permissions.Copy,
                            viewMode: models.ViewMode.View,
                          }}
                          cssClassName={reportClass}
                          getEmbeddedComponent={(
                            embedObject: powerbi.Embed
                          ) => {
                            setReport(embedObject as powerbi.Report);
                          }}
                        />
                      )}
                    </div>
                    {isCopilotOpen && report && (
                      <ReportCopilot reportEmbed={report} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportView;
