import axios from "axios";
import React, { useEffect, useState } from "react";
import AdminDialogueBox from "./AdminDialogueBox";
import AdminNavListItem from "./AdminNavListItem";
import AddNewNavItemDialogueBox from "./AddNewNavItemDialogueBox";
import protectedAxiosInstance from "./helpers/api";
import AdminSelectComponent from "./AdminSelectComponent";
import Loading from '../Images/Loading.gif'

interface props {
  editModeTab: string;
  setEditMode: (value: string) => void;
}

interface diff {
  edit: {
    [tileId: string]: {
      before: string;
      after: string;
      type: string;
    }
  };
  add: {
    [tileId: string]: {
      after: string;
      type: string;
    }
  };
  delete: {
    [tileId: string]: {
      after: string;
      type: string;
    }
  };
}

const AdminNavList: React.FC<props> = ({ editModeTab, setEditMode }) => {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isError, setIsError] = useState(false); // Error state
  const [navList, setNavList] = useState<any>([]);
  const [navListTableView, setNavListTableView] = useState<any>([]);
  const [horizontalNavList, setHorizontalNavList] = useState<any>();
  const [editableHorizontalNavList, setEditableHorizontalNavList] = useState<any>();
  const [dataList, setDataList] = useState<any>({});
  const [addNewTab, setAddNewTab] = useState<boolean>(false);
  const [newParentTabText, setNewParentTabText] = useState<any>("");
  const [tableView, setTableView] = useState<any>("");
  const [intitialDataList, setInitialDataList] = useState<any>({});
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDeleteParentTab, setShowDeleteParentTab] = useState<boolean>(false);
  const [selectedParentTab, setSelectedParentTab] = useState<any>("");
  const [inEditMode, setInEditMode] = useState<any>([]);
  const [showAddToTop, setShowAddToTop] = useState<boolean>(false);
  const [newParentTileId, setNewParentTileId] = useState<any>("");
  const [secondSelectValue, setSecondSelectValue] = useState<string>("select");  
  const [secondLevelData, setSecondLevelData] = useState<any>([]);
  const [thirdLevelData, setThirdLevelData] = useState<any>([]);
  const [isFolderAdditionAllowed, setisFolderAdditionAllowed] = useState<boolean>(true);
  const [isReportAdditionAllowed, setIsReportAdditionAllowed] = useState<boolean>(false);
  const [isConfigValid, setIsConfigValid] = useState<boolean>(false);
  const [navJsonDiff, setNavJsonDiff] = useState<diff>({ edit: {}, add: {}, delete: {} });
  
  // Edit changes validation
  const  validateConfig = () => {
    let flag = false;
    if (selectedParentTab === "") {          // top level check
      navListTableView.forEach((item: any, index: number) => {
        if (item.tileText === "") {
          flag = true;
        }
      });
    }
    else if (selectedParentTab !== "") {     // second and below level check
      dataList[tableView].subPages.forEach((item: any, index: number) => {
        if (item.tileId.includes("new")){
        }
        if (item.tileText === "") {
          flag = true;
        }
      });
    }
    return flag;
  }

  const shiftElement = (arr: any[], index: number, direction: string) => {
    if (index < 0 || index >= arr.length) {
      return arr;  // Invalid index, return original array  
    }
  
    if ((direction === 'up' && index === 0) || 
        (direction === 'down' && index === arr.length - 1)) {
      return arr;  // Can't shift further in this direction, return original array
    }

    let shiftBy = 1;
    if (direction === 'up' && arr[index - 1].tileId === "OCPLanding" && 0 <= index - 2) {
      shiftBy = 2;
    } else if (direction === 'down' && arr[index + 1].tileId === "OCPLanding" && arr.length > index + 2) {
      shiftBy = 2;
    }
    
    const newIndex = (direction === 'down') ? (index + shiftBy) : (index - shiftBy);
    [arr[index], arr[newIndex]] = [arr[newIndex], arr[index]];
    return arr;
  }

  const reOrder = (index: number, direction: string) => {
    if (selectedParentTab === "" && tableView === "") {
      const tmpNavListTableView = [ ...navListTableView ];
      setNavListTableView(shiftElement(tmpNavListTableView, index, direction));
    }else{
      const tmpDataList = { ...dataList };
      tmpDataList[tableView].subPages = shiftElement(tmpDataList[tableView].subPages, index, direction);
      setDataList(tmpDataList);
    }
  }

  const handleChangeTileText = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    tileID: string
  ) => {
    const tmpDataList = { ...dataList };
    const tmpNavListTableView = [ ...navListTableView ];

    if (selectedParentTab === "" && tableView === "" && secondSelectValue === "select") {
      if (!navListTableView[index].tileId.includes("-new-")){
        setNavJsonDiff((prevState) => { // register the edit in navJsonDiff object
          const report = navListTableView[index];
          const existingEdit = prevState.edit[report.tileId];
          return {
            ...prevState,
            edit: {
              ...prevState.edit,
              [report.tileId]: {
                before: (existingEdit ? existingEdit.before : `${report.tileText} -`),
                after: `${e.target.value} -`,
                type: "Section",
              },
            },
          };
        });
      }
      tmpDataList[tileID].tileText = e.target.value;        // then update the tileText in state
      tmpNavListTableView[index].tileText = e.target.value; // then update the tileText in state
    }else{
      if (!dataList[tableView].subPages[index].tileId.includes("-new-")){

        setNavJsonDiff((prevState) => { // register the edit in navJsonDiff object
          const report = dataList[tableView].subPages[index];
          const existingEdit = prevState.edit[report.tileId];
          let constructPath = "";
          if (navListTableView.some((navItem: any) => navItem.tileId === tableView)) {
            constructPath = `${dataList[tableView].tileText}`;
          } else {
            const section = navListTableView.find((navItem: any) =>
              navItem.subPages.some((subItem: any) => subItem.tileId === tableView)
            );
            constructPath = `${section.tileText} - ${dataList[tableView].tileText}`;
          }
          return {
            ...prevState,
            edit: {
              ...prevState.edit,
              [report.tileId]: {
                before: (existingEdit ? existingEdit.before : `${constructPath} - ${report.tileText}${report.hasSubPages ? " -": ""}`),
                after: `${constructPath} - ${e.target.value}${report.hasSubPages ? " -": ""}`,
                type: report.hasSubPages ? "Subsection" : "Report",
              },
            },
          };
        });

      }
      tmpDataList[tableView].subPages[index].tileText = e.target.value; // then update the tileText in state
    }
    setDataList(tmpDataList);
    setNavListTableView(tmpNavListTableView); // set the state
  };

  const handleDelete = (index: number, isFolder: boolean) => {
    
    // delete top level section and early return
    if (isFolder && tableView === "") {
      handleDeleteSection(index)
      return
    }

    const tmpDataList = JSON.parse(JSON.stringify(dataList));
    const tileId = tmpDataList[tableView].subPages[index].tileId;
    tmpDataList[tableView].subPages.splice(index, 1);
    if (!isFolder) {
      handleDeleteReport(index);
    } else {
      handleDeleteFolder(index);
    }
    setDataList(tmpDataList);
    setInEditMode(inEditMode.filter((item: any) => item !== tileId));
    if (index === 0 && tmpDataList[tableView].subPages.length === 0) {
      setShowDialog(true);
    }
  };

  const handleDeleteReport = (index: number) => {

    // #region : register the deletion in navJsonDiff object
    let deletedItem: diff["delete"];
    const report = dataList[tableView].subPages[index];
    const isUnderSection = navListTableView.some(
      (navItem: any) => navItem.tileId === tableView
    );
    if (isUnderSection) {
      // Report is directly under a section
      deletedItem = {
        [report.tileId]: {
          after: `${dataList[tableView].tileText} - ${report.tileText}`,
          type: "Report",
        }
      }
      //`${dataList[tableView].tileText} - ${report.tileText}`;
    }
    const section = navListTableView.find((navItem: any) =>
      navItem.subPages.some((subItem: any) => subItem.tileId === tableView)
    );

    if (section) {
      deletedItem = {
        [report.tileId]: {
          after: `${section.tileText} - ${dataList[tableView].tileText} - ${report.tileText}`,
          type: "Report",
        }
      }
    }

    setNavJsonDiff((prevState) => {
      return {
        ...prevState,
        delete: {
          ...prevState.delete, 
          ...deletedItem
        },
      };
    });
    //#endregion

    // now delete from state variable
    const updatedHorizontalNavList = { ...editableHorizontalNavList };
    delete updatedHorizontalNavList[
      dataList[tableView].subPages[index].tileId
    ];
    setEditableHorizontalNavList(updatedHorizontalNavList);
  };

  const handleDeleteFolder = (index: number) => {
    // #region : register the deletion in navJsonDiff object
    const folder = dataList[tableView].subPages[index];
    // setNavJsonDiff((prevState) => {
    //   return {
    //     ...prevState,
    //     delete: [...prevState.delete, `${dataList[tableView].tileText} - ${folder.tileText} -`],
    //   };
    // });
    setNavJsonDiff((prevState) => {
      return {
        ...prevState,
        delete: {
          ...prevState.delete, 
          [folder.tileId]: {
            after: `${dataList[tableView].tileText} - ${folder.tileText} -`,
            type: "Subsection",
          }
        },
      };
    });

    // Report is under a subsection
    const sectionItem = navListTableView.find((navItem: any) =>
      navItem.subPages.some((subItem: any) => subItem.tileId === tableView)
    );
    //#endregion
    // now delete from state variable    
    const tmpDataList = JSON.parse(JSON.stringify(dataList));
    tmpDataList[tableView].subPages[index].subPages.forEach(
      (item: any) => {
        const updatedHorizontalNavList = { ...editableHorizontalNavList };
        delete updatedHorizontalNavList[item.tileId];
        setEditableHorizontalNavList(updatedHorizontalNavList);
      }
    );
  };

  const handleDeleteSection = (index: number) => {
    // #region : register the deletion in navJsonDiff object
    const section = navListTableView[index];
    setNavJsonDiff((prevState) => {
      return {
        ...prevState,
        delete: {
          ...prevState.delete, 
          [section.tileId]: {
            after: `${section.tileText} -`,
            type: "Section",
          }
        },
      };
    });
    //#endregion
    // now delete from state variable
    let tmpNavListTableView = [ ...navListTableView ];
    tmpNavListTableView.splice(index, 1)
    setNavListTableView(tmpNavListTableView);
  };

  const handleAddNewTab = (index?: number) => {
    const tmpDataList = { ...dataList };
    const newTab = {
      tileId: `-new-${Math.floor(Math.random() * 1000)}`,
      tileText: "",
      hasSubPages: false,
      subPages: [],
    };
    if (index === undefined) {
      tmpDataList[tableView].subPages.unshift(newTab);
    } else {
      tmpDataList[tableView].subPages.splice(index + 1, 0, newTab);
    }
    setDataList(tmpDataList);
  };

  const handleAddNewFolder = (index?: number) => {
    const tmpDataList = { ...dataList };
    const tmpNavListTableView = [ ...navListTableView ]
    const newTab = {
      tileId: `-new-${Math.floor(Math.random() * 1000)}`,
      tileText: "",
      hasSubPages: true,
      subPages: [],
      type: selectedParentTab ? "Subsection" : "Section"
    };
    if (tableView !== "") {
      if (index === undefined) {
        tmpDataList[tableView].subPages.unshift(newTab);
      } else {
        tmpDataList[tableView].subPages.splice(index + 1, 0, newTab);
      }
    } else if (tableView === "") {
      if (index === undefined) {
        tmpDataList[newTab.tileId] = newTab;
        tmpNavListTableView.unshift(newTab);
      } else {
        tmpDataList[newTab.tileId] = newTab;
        tmpNavListTableView.splice(index + 1, 0, newTab);
      }
    }
    setDataList(tmpDataList);
    setNavListTableView(tmpNavListTableView)
  };

  const handleAddNewParentTab = () => {
    //add new parent tab to dataList
    const tmpDataList = { ...dataList };
    const newParentTab = {
      tileId: createTileId(newParentTabText),
      tileText: newParentTabText,
      hasSubPages: true,
      subPages: [],
    };
    tmpDataList[newParentTab.tileId] = newParentTab;
    setDataList(tmpDataList);
  };

  const handleCancel = () => {
    const tmpHorizontalList = JSON.parse(JSON.stringify(horizontalNavList));
    const tmpDataList = JSON.parse(JSON.stringify(intitialDataList));
    const tmpNavList = JSON.parse(JSON.stringify(navList));
    setDataList(tmpDataList);
    setEditableHorizontalNavList(tmpHorizontalList);
    setNavListTableView(tmpNavList)
    setInEditMode([]);
    setEditMode("");
    setNavJsonDiff({ edit: {}, add: {}, delete: {} });    // reset the diff object
  };

  const handleCancelNewParentTab = () => {
    setAddNewTab(false);
    setNewParentTabText("");
  };

  const createTileId = (tileText: string) => {
    //create unique tileId from tileText
    const text = tileText.replace(/\s/g, "");
    //take first 3 characters and last 3 characters
    const tileId =
      text.substring(0, 3) +
      text.substring(text.length - 3) +
      Math.floor(Math.random() * 1000);
    setNewParentTileId(tileId);
    return tileId;
  };

  const updateNavList = async (navList: any, tmpNavJsonDiff: diff) => {
    try {
      await protectedAxiosInstance.post(
        "/api/service/UpdateNavigationConfiguration",
        {
          Type: "NAV_TILE_LIST",
          Data: JSON.stringify(JSON.stringify(navList)),
        },
        {
          withCredentials: true,
        }
      );
      const res = await protectedAxiosInstance.post(
        "/api/service/PostSyncReportLibraryConfiguration",
        {
          ...convertDiffToNavJsonDiff(tmpNavJsonDiff),
        },
        {
          withCredentials: true,
        }
      );
      console.log("NavList updated successfully");
      
    } catch (error) {
      console.error("Error in updating horizontalTileList", error);
    }
  };

  const updateHorizontalNavList = async (tmpHorizontalList: any) => {
    try {
      await protectedAxiosInstance.post(
        "/api/service/UpdateNavigationConfiguration",
        {
          Type: "HORIZONTAL_TILE_LIST",
          Data: JSON.stringify(JSON.stringify(tmpHorizontalList)),
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error in updating horizontalTileList", error);
    }
  };

  const handleDeleteParentTab = async () => {
    //selectedParentTab is delted from navList
    const tmpDataList = { ...dataList };
    delete tmpDataList[selectedParentTab];

    const tmpNavList = JSON.parse(JSON.stringify(navList));
    tmpNavList.forEach((item: any) => {
      if (item?.tileId === selectedParentTab) {
        const idx = tmpNavList.indexOf(item);
        tmpNavList.splice(tmpNavList.indexOf(item), 1);

        navList[idx].subPages.forEach((subpage: any) => {
          if (subpage.hasSubPages) {
            subpage.subPages.forEach((report: any) => {
              const updatedHorizontalNavList = { ...editableHorizontalNavList };
              delete updatedHorizontalNavList[report.tileId];
              updateHorizontalNavList(updatedHorizontalNavList);
            });
          } else {
            const updatedHorizontalNavList = { ...editableHorizontalNavList };
            delete updatedHorizontalNavList[subpage.tileId];
            updateHorizontalNavList(updatedHorizontalNavList);
          }
        });
      } else {
        item?.subPages.forEach((subpage: any) => {
          if (  subpage.tileId === selectedParentTab) {
            item.subPages.splice(item.subPages.indexOf(subpage), 1);
            subpage.subPages.forEach((report: any) => {
              const updatedHorizontalNavList = { ...editableHorizontalNavList };
              delete updatedHorizontalNavList[report.tileId];
              updateHorizontalNavList(updatedHorizontalNavList);
            });
          }
        });
      }
    });

    updateNavList(tmpNavList, navJsonDiff);
    setSelectedParentTab("");
  };

  const getAddedDiff = (item: any, tableView: string): diff["add"] => {
    // Adding a record to JSON diff
    const parentTileText = dataList[tableView].tileText;
    const itemTileText = item.tileText;

    // Item is a report
    const isUnderSection = navListTableView.some(
      (navItem: any) => navItem.tileId === tableView
    );

    if (isUnderSection) {
      // Report is directly under a section
      return {
        [item.tileId]: {
        after: `${parentTileText} - ${itemTileText}`,
        type: "Report",
      }};
    }

    // Report is under a subsection
    const sectionItem = navListTableView.find((navItem: any) =>
      navItem.subPages.some((subItem: any) => subItem.tileId === tableView)
    );

    const sectionTitle = sectionItem?.tileText || 'Unknown Section';
    return {
      [item.tileId]: {
        after: `${sectionTitle} - ${parentTileText} - ${itemTileText}`,
        type: "Report",
      }
    };
  };

  const handleSave = async () => {
    //check if new tab is added
    const tmpDataList = { ...dataList };
    const tmpNavList = [ ...navListTableView ];
    let tmpNavJsonDiff: diff = {...navJsonDiff}
    if ( tableView !== "") { // Modifying sub section/report
      tmpDataList[tableView]?.subPages.forEach((item: any) => { 
        if (item.tileId.includes("-new-")) { // Adding subsection 
          item.tileId = createTileId(item.tileText);
          
          // Adding to diff only if it is a new report
          if (!item.hasSubPages){
            tmpNavJsonDiff = {
              ...navJsonDiff,
              add: {
                ...navJsonDiff.add,
                ...getAddedDiff(item, tableView), // register the addition in navJsonDiff object
              },
            };
          }
        }
      });
      //find selectedParentTab in navList and update it
      tmpNavList.forEach((item: any) => {
        if (item != null) {
          if (item.tileId === tableView) {
            item.subPages = tmpDataList[tableView].subPages; // overwrite sections's subPages (array of reports/subsections)
          } else {
            item.subPages.forEach((subpage: any) => {
              if (subpage.tileId === tableView) {
                subpage.subPages = tmpDataList[tableView].subPages; // overwrite sub sections's subPages (array of reports)
              }
            });
          }
        }
      });

      updateHorizontalNavList(editableHorizontalNavList);
    } else {
      //new parent tab is added or modified
      tmpNavList.forEach((item: any) => {
        if (item.tileId.includes("-new-")) {
          item.tileId = createTileId(item.tileText);
          
          // Adding to diff only if it is a new report
          if (!item.hasSubPages){
            tmpNavJsonDiff = {
              ...navJsonDiff,
              add: {
                ...navJsonDiff.add,
                ...getAddedDiff(item, tableView), // register the addition in navJsonDiff object
              },
            };
          }
        }
      });
    }

    updateNavList(tmpNavList, tmpNavJsonDiff);
  };

  const setLevelwiseTileData = (navList: any) => {
    let tempSecondLevelData: any = {},
      tempThirdLevelData: any = {};

    navList.forEach((item: any, index: number) => {
      // if(index === 0) return
      if (index > 0) {
        if (item.hasSubPages) {
          // map subPages to the tileId
          tempSecondLevelData[item.tileId] = item.subPages;
          // map third level data to the subPages
          item.subPages.forEach((subPage: any) => {
            if (subPage.hasSubPages) {
              tempThirdLevelData[subPage.tileId] = subPage.subPages;
            } else {
              tempThirdLevelData[subPage.tileId] = [];
            }
          });
        } else {
          tempSecondLevelData[item.tileId] = [];
        }
      }
    });
    setSecondLevelData(tempSecondLevelData);
    setThirdLevelData(tempThirdLevelData);
  };

  const fetchNParseNavList = async () => {
    try {
      // fetch navList and horizontalNavList
      const navListPromise = protectedAxiosInstance.get("/api/service/GetNavlist", {
        withCredentials: true,
      });
      const horizontalNavListPromise = protectedAxiosInstance.get("/api/service/GetHorizontalTileList", {
          withCredentials: true,
      });
      const [navListResponse, horizontalNavListResponse] = await Promise.all([navListPromise, horizontalNavListPromise]);

      // parse navList
      const tmpnavList = JSON.parse(
        JSON.parse(navListResponse.data.Table[0].ListData)
      );
      setLevelwiseTileData(tmpnavList);
      setNavList(JSON.parse(JSON.parse(navListResponse.data.Table[0].ListData)));
      tmpnavList.forEach((item: any) => {
          item["type"] = "Section";
      })
      setNavListTableView(tmpnavList);
      // traverse tmpnavList and get keys for which hasSubpages is true
      let tmpDataList: any = {};
      tmpnavList.forEach((item: any) => {
        if (item != null && item.hasSubPages) {
          item["type"] = "Section";
          tmpDataList[item.tileId] = item;
          item.subPages.length > 0 &&
            item.subPages.forEach((subpage: any) => {
              if (subpage.hasSubPages) {
                subpage["type"] = "Subsection";
                tmpDataList[subpage.tileId] = subpage;
              }
            });
        }
      });
      let tmpDataList2 = JSON.parse(JSON.stringify(tmpDataList));
      setInitialDataList(tmpDataList2);
      setDataList(tmpDataList);

      // parse horizontalNavList
      const tmpHorizontalNavList1 = JSON.parse(
        JSON.parse(horizontalNavListResponse.data.Table[0].ListData)
      );
      const tmpHorizontalNavList2 = JSON.parse(
        JSON.parse(horizontalNavListResponse.data.Table[0].ListData)
      );
      setHorizontalNavList(tmpHorizontalNavList1);
      setEditableHorizontalNavList(tmpHorizontalNavList2);
      setIsLoading(false);
    } catch (error) {
      console.error("Error", error);
      setIsLoading(false);
      setIsError(true);
    }
  };
  
  useEffect(() => {
    fetchNParseNavList();
  }, []);

  useEffect(() => {
    setIsConfigValid(()=>{return validateConfig()});
  },[dataList, navListTableView])

  if (isError){
    return (
      <div className="ErrorReports">
      <center>
        <h2>:(</h2>
        <h4>
          Something Went Wrong,{" "}
          <span className="TryAgainLink" onClick={() => fetchNParseNavList()}>
            Try Again
          </span>
        </h4>
      </center>
    </div>
    )
  }
  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={Loading} alt="Loading..." className="loader" />
      </div>
    );
  }

  return (
    <div className="containerList">
      {dataList && (
        <div className="NavBarDropdowns" style={{display: "flex", alignItems: "flex-end", flexWrap: "wrap"}}>
            {navList && (
              <div className="selectTabComponent">
                <div className="selectTabText">Select Section</div>
                <AdminSelectComponent
                  onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setTableView(e.target.value);
                    setSelectedParentTab(()=>e.target.value);
                    setSecondSelectValue("select");
                    setEditableHorizontalNavList(
                      JSON.parse(JSON.stringify(horizontalNavList))
                    );
                    setIsReportAdditionAllowed(e.target.value ? true : false);
                  }}
                  title="Select Section"
                  options={navList}
                  selectedValue={selectedParentTab}
                  isParentPresent={true}
                  parentName="Sections"
                  parentId="" // "" null signifying no selection
                />
              </div>
            )}

            {tableView !== "" && secondLevelData[selectedParentTab]?.length > 0 && (
              <div className="selectTabComponent childSelectTabComponent">
                <div className="selectTabText">Select Subsection</div>
                <AdminSelectComponent
                  onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setTableView(e.target.value);
                    setSecondSelectValue(e.target.value);
                    setIsReportAdditionAllowed(true)
                    setisFolderAdditionAllowed(e.target.value === selectedParentTab ? true : false);
                  }}
                  isParentPresent={true}
                  parentName="Subsection Parent"
                  parentId={selectedParentTab}
                  title="Select Report/Subsection"
                  options={secondLevelData[selectedParentTab]}
                  selectedValue={secondSelectValue}
                  ignoreSubpages={true}
                />
              </div>
            )}
        </div>
      )}

      {addNewTab && (
        <div className="addNewParentTabContainer">
          <input
            className="inputNewParentStyle"
            value={newParentTabText}
            onChange={(e) => setNewParentTabText(e.target.value)}
            type="text"
            placeholder="Enter Section Name"
          />
          <button
            disabled={newParentTabText.length === 0 || newParentTabText === " "}
            style={{
              backgroundColor: `${
                newParentTabText.length !== 0 ? "#0078d4" : "#f5f3f3"
              }`,
              color: `${newParentTabText.length !== 0 ? "White" : "Black"}`,
            }}
            onClick={() => {
              handleAddNewParentTab();
              setShowDialog(true);
            }}
          >
            Add
          </button>
          <button
            onClick={() => {
              handleCancelNewParentTab();
            }}
          >
            Cancel
          </button>
        </div>
      )}

{/*
if selectedParentTab is null
  if navList length is greater than 0 then show the table
  else show the add new section button
*/}

      {(navListTableView && !selectedParentTab) &&
        (navListTableView.length > 0 ? (
          <div className="tableContainer">
            <div className="listContainer">
              <table className="navListTable">
                <thead>
                  <tr
                    style={{ position: "sticky", top: "0" }}
                    className="navListTableHeader"
                  >
                    <th className="navListTableHeaderSrNo">Order</th>
                    <th className="navListTableHeaderTileText">Name</th>
                    <th className="navListTableHeaderType">Type</th>
                    <th className="navListTableHeaderActions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {navListTableView.map(
                    (item: any, index: number) => {
                      // return only if the tileId is not OCPLanding
                      if (item.tileId !== "OCPLanding") {
                        return (
                          <AdminNavListItem
                            key={item.tileId}
                            item={item}
                            index={index}
                            parentTab={selectedParentTab}
                            inEditMode={inEditMode}
                            setEditModeTab={(value: string) => setEditMode(value)}
                            setEditMode={(value: any) => setInEditMode(value)}
                            handleDelete={handleDelete}
                            handleAddNewTab={handleAddNewTab}
                            handleAddNewFolder={handleAddNewFolder}
                            handleChangeTileText={handleChangeTileText}
                            isFolderAdditionAllowed={isFolderAdditionAllowed}
                            isReportAdditionAllowed={false}
                            reOrder={reOrder}
                          />
                        );
                      }
                    }
                  )}
                </tbody>
              </table>
            </div>
            <button
              onClick={() => {
                setEditMode("navList");
                setShowAddToTop(true);
              }}
              style={{
                margin : "10px 0px 20px 0px",
              }}
            >
              Add new item to top
            </button>
            <div className="buttonContainer">
              <button
                onClick={() => {
                  setShowDialog(true);
                }}
                disabled={
                  isConfigValid ||
                  editModeTab === "horizontalList" ||
                  editModeTab === ""
                }
                style={{
                  backgroundColor: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "#f5f3f3"
                      : "#0078d4"
                  }`,
                  color: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "Black"
                      : "White"
                  }`,
                }}
              >
                Save
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              height: "80px",
            }}
          >
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewTab(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Report</span>
            </div>
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewFolder(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Subsection</span>
            </div>
          </div>
        ))}


{/*
else if selectedParentTab is not null and 
  if subPages length is greater than 0 then show the table 
  else show the add new report and add new subsection buttons
*/}
      {selectedParentTab &&
        (dataList[selectedParentTab].subPages.length > 0 ? (
          <div className="tableContainer">
            <div className="listContainer">
              <table className="navListTable">
                <thead>
                  <tr
                    style={{ position: "sticky", top: "0" }}
                    className="navListTableHeader"
                  >
                    <th className="navListTableHeaderSrNo">Order</th>
                    <th className="navListTableHeaderTileText">Name</th>
                    <th className="navListTableHeaderType">Type</th>
                    <th className="navListTableHeaderActions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList[tableView].subPages.map(
                    (item: any, index: number) => {
                      return (
                        <AdminNavListItem
                          key={item.tileId}
                          item={item}
                          index={index}
                          parentTab={selectedParentTab}
                          inEditMode={inEditMode}
                          setEditModeTab={(value: string) => setEditMode(value)}
                          setEditMode={(value: any) => setInEditMode(value)}
                          handleDelete={handleDelete}
                          handleAddNewTab={handleAddNewTab}
                          handleAddNewFolder={handleAddNewFolder}
                          handleChangeTileText={handleChangeTileText}
                          isFolderAdditionAllowed={isFolderAdditionAllowed}
                          reOrder={reOrder}
                        />
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            <button
              onClick={() => {
                setEditMode("navList");
                setShowAddToTop(true);
              }}
              style={{
                margin : "10px 0px 20px 0px",
              }}
            >
              Add new item to top
            </button>
            <div className="buttonContainer">
              <button
                onClick={() => {
                  setShowDialog(true);
                }}
                disabled={
                  isConfigValid ||
                  editModeTab === "horizontalList" ||
                  editModeTab === ""
                }
                style={{
                  backgroundColor: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "#f5f3f3"
                      : "#0078d4"
                  }`,
                  color: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "Black"
                      : "White"
                  }`,
                }}
              >
                Save
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              height: "80px",
            }}
          >
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewTab(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Report</span>
            </div>
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewFolder(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Subsection</span>
            </div>
          </div>
        ))}
      {showDialog && (
        <AdminDialogueBox
          message="Are you sure you want to save the changes?"
          buttonText="Save"
          saveChanges={handleSave}
          setShowDialogue={(value: boolean) => setShowDialog(value)}
          onCancel={handleCancel}
        />
      )}
      {showDeleteParentTab && (
        <AdminDialogueBox
          message="Are you sure you want to delete the Section? This action cannot be undone."
          buttonText="Delete"
          saveChanges={handleDeleteParentTab}
          setShowDialogue={(value: boolean) => setShowDeleteParentTab(value)}
          onCancel={handleCancel}
        />
      )}
      {showAddToTop && (
        <AddNewNavItemDialogueBox
          addNewReport={() => handleAddNewTab()}
          addNewFolder={() => handleAddNewFolder()}
          setShowDialogue={(value) => setShowAddToTop(value)}
          message="This new item will be added to the top of the selected Section/Subsection. Select the type of item to add:"
          isReportAdditionAllowed={isReportAdditionAllowed}
          additionType={selectedParentTab ? "Subsection" : "Section"}
          isFolderAdditionAllowed={isFolderAdditionAllowed}
        />
      )}
    </div>
  );
};

export default AdminNavList;
interface Edit {
  tileId: string;
  before: string;
  after: string;
  type: string;
}

interface Add {
  tileId: string;
  after: string;
  type: string;
}

interface Delete {
  tileId: string;
  after: string;
  type: string;
}

interface NavJsonDiff {
  Edit: Edit[];
  Add: Add[];
  Delete: Delete[];
}

function convertDiffToNavJsonDiff(diff: diff): NavJsonDiff {
  const editArray: Edit[] = Object.keys(diff.edit).map(tileId => ({
    tileId,
    before: diff.edit[tileId].before,
    after: diff.edit[tileId].after,
    type: diff.edit[tileId].type
  }));

  const addArray: Add[] = Object.keys(diff.add).map(tileId => ({
    tileId,
    after: diff.add[tileId].after,
    type: diff.add[tileId].type
  }));

  const deleteArray: Delete[] = Object.keys(diff.delete).map(tileId => ({
    tileId,
    after: diff.delete[tileId].after,
    type: diff.delete[tileId].type
  }));

  return {
    Edit: editArray,
    Add: addArray,
    Delete: deleteArray
  };
}