import React, { useState, useEffect, useRef } from "react";
import LeftNav from "./Components/LeftNav";
import AppRouter from "./Components/AppRouter";
import "./Styles/report.css";
import "./Styles/LandingPage.css";
import "./Styles/app.css";
// import './Styles/dynamicbullet.css'
import "./Styles/Hub.css";
import "./Styles/popup.css";
import Header from "./Components/Header";
import axios from "axios";
import { Constants } from "./Components/helpers/Constants";
import protectedAxiosInstance from "./Components/helpers/api";
import { BrowserRouter } from "react-router-dom";

const ProtectedApp = () => {
  const [triggerView, setTriggerView] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [role, setRole] = useState<string>("");
  const [isPowerUser, setIsPowerUser] = useState<boolean>(false);
  const [graphData, setGraphData] = useState(null);
  const adminRef = useRef<HTMLDivElement>(null);

  const fetchUserImage = (accessToken: any) => {
    axios
      .get("https://graph.microsoft.com/v1.0/me/photo/$value", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        let imgUrl = window.URL.createObjectURL(response.data);
        sessionStorage.setItem("UserImage", imgUrl);
      })
      .catch((result) => {
        console.error("Error", result);
      });
  };

  const setTitle = () => {
    const url = window.location.host;
    if (url.includes("localhost")) {
      document.title = "FastTrack BI - LOCAL";
    }
    if (url.includes("dev")) {
      document.title = "FastTrack BI - DEV";
    }
    if (url.includes("uat")) {
      document.title = "FastTrack BI - UAT";
    }
    if (url.includes("prod") || url.includes("ftbi.microsoft.com")) {
      document.title = "FastTrack BI - PROD";
    }
  };
  const fetchData = async () => {
    try {
      const response = await protectedAxiosInstance.post(
        "api/Service/GetDataWithConfiguration",
        {
          configId: "2",
        },
        {
          withCredentials: true,
        }
      );
      const jsonResponse = response.data;
      localStorage.setItem("powerbi_config", JSON.stringify(jsonResponse));
    } catch (error) {
      console.error("Error while fetching powerbi Config", error);
    }
  };

  useEffect(() => {
    // console.log('isEditing in Protected App', isEditing)
    // console.log('openDialogue in Protected App', openDialogue)
  }, [isEditing, openDialogue]);
  const temp = (tile: string) => {
    setTriggerView(tile);
  };

  const handleIsEditing = (value: boolean) => {
    setIsEditing(value);
    // console.log('isEditing in Protected App', isEditing)
  };

  const handleOpenDialogue = (value: boolean) => {
    setOpenDialogue(value);
    // console.log('openDialogue in Protected App', openDialogue)
  };

  const getAccessRole = async (userName: string) => {
    try {
      const response = await protectedAxiosInstance.post(
        "/api/service/GetAccessRole",
        {
          UserAlias: userName.split("@")[0],
        },
        {
          withCredentials: true,
        }
      );
      setRole(response.data.Table[0].AccessRole);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getPowerUsers = async (userName: string) => {
    try {
      const response = await protectedAxiosInstance.post(
        "/api/service/isPowerUser",
        { UserAlias: userName.split("@")[0] },
        { withCredentials: true }
      );
      const isPowerUser = response.data?.isPowerUser || response.data?.Table?.[0]?.isPowerUser;
      if (isPowerUser === "TRUE") {
        setIsPowerUser(true);
      } else {
        setIsPowerUser(false);
      }
    } catch (error) {
      console.error("Error checking PowerUser status:", error);
    }
  };

  useEffect(() => {
    const userDetails = localStorage.getItem("user");
    getAccessRole(userDetails ? JSON.parse(userDetails).mail : ""),
      getPowerUsers(userDetails ? JSON.parse(userDetails).mail : "");

    setTitle();
    // add a back button event listener to go back to the previous page
    window.addEventListener("popstate", (event) => {
      // give a unique random string to the tab to trigger the useEffect
      setTriggerView(Math.random().toString(36).substring(7));
    });
    fetchData();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Header />
        <div
          className="ReportDataSection"
          style={{
            display: "flex",
            height: "100%",
            userSelect: "none",
          }}
        >
          <LeftNav
            role={role}
            setTab={temp}
            editing={isEditing}
            handleOpenDialogue={handleOpenDialogue}
          />
          <div
            className="ReportingSection "
            style={{ width: "100%", height: "100vh", position: "relative" }}
            ref={adminRef}
          >
            <AppRouter
              role={role}
              isPowerUser={isPowerUser}
              tab={triggerView}
              openDialogue={openDialogue}
              setEditing={handleIsEditing}
              handleOpenDialog={handleOpenDialogue}
              adminRef={adminRef}
            />
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};

export default ProtectedApp;
