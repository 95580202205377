import React, { useEffect, useState } from "react";
import protectedAxiosInstance from "./helpers/api";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import ELK from 'elkjs/lib/elk.bundled.js';
import {
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
  Controls,
  Background,
  Edge,
  Node,
  MarkerType
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import "./DataModel.css";
import RelationshipTable from "./RelationshipTable";
import { IconButton, TooltipHost } from "@fluentui/react";
 
// Define the types for the data model
interface RelationshipDataModel {
  fromTable: string;
  toTable: string;
  fromColumn: string;
  toColumn: string;
  relationshipCardinality: string;
}
 
interface DataModelProps {
  reportId: string;
  workspaceId: string;
}
 
const DataModel: React.FC<DataModelProps> = ({ reportId, workspaceId }) => {
  const [relationshipData, setRelationshipData] = useState<RelationshipDataModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [tableOptions, setTableOptions] = useState<IDropdownOption[]>([]);
  const [selectedTable, setSelectedTable] = useState<string | null>("All");
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [isGraphReady, setIsGraphReady] = useState<boolean>(false);
 
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
 
      try {
        const response = await protectedAxiosInstance.get("/api/service/GetDatasetRelationship", {
          params: { reportId, workspaceId },
          withCredentials: true,
        });
 
        const data: RelationshipDataModel[] = response.data.relationshipList;
        setRelationshipData(data);
 
        // Extract unique table names for the dropdown
        const uniqueTables = Array.from(
          new Set(data.flatMap((rel) => [rel.fromTable, rel.toTable]))
        );
        setTableOptions([
          { key: "All", text: "All" },
          ...uniqueTables.map((table) => ({ key: table, text: table })),
        ]);
        transformToGraph(data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Data Model Not Available");
      } finally {
        setLoading(false);
      }
    };
 
    fetchData();
  }, [reportId, workspaceId]);
 
 
  const handleTableChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setSelectedTable(option.key as string);
      setSelectedEdge(null);
      setIsGraphReady(false);
      if (option.key !== "All") {
        const filteredRelationships = relationshipData.filter(
          (rel) => rel.fromTable === option.key || rel.toTable === option.key
        );
        transformToGraph(filteredRelationships);
      } else {
        transformToGraph(relationshipData);
      }
    }
  };
 
  const handleEdgeClick = (event: React.MouseEvent, edge: Edge) => {
    setSelectedEdge(edge);
  };
 
  const transformToGraph = async (data: RelationshipDataModel[]) => {
    const nodeSet = new Set<string>();
    const generatedNodes: Node[] = [];
    const edgeSet = new Set<string>();
    const generatedEdges: Edge[] = [];
 
    // Generate nodes and edges
    data.forEach((relationship) => {
      if (relationship.fromTable && !nodeSet.has(relationship.fromTable)) {
        nodeSet.add(relationship.fromTable);
        generatedNodes.push({
          id: relationship.fromTable,
          data: { label: relationship.fromTable },
          position: { x: 0, y: 0 },
          draggable: true,
        });
      }
      if (relationship.toTable && !nodeSet.has(relationship.toTable)) {
        nodeSet.add(relationship.toTable);
        generatedNodes.push({
          id: relationship.toTable,
          data: { label: relationship.toTable },
          position: { x: 0, y: 0 },
          draggable: true,
        });
      }
 
      const edgeKey = `${relationship.fromTable}-${relationship.toTable}`;
      if (!edgeSet.has(edgeKey)) {
        edgeSet.add(edgeKey);
        generatedEdges.push({
          id: edgeKey,
          source: relationship.fromTable,
          target: relationship.toTable,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          style: { strokeWidth: 2 },
          data: {
            fromColumn: relationship.fromColumn,
            toColumn: relationship.toColumn,
            relationshipCardinality: relationship.relationshipCardinality,
          },
        });
      }
    });
 
    // Apply ELK layout
    const elkNodesAndEdges = await applyElkLayout(generatedNodes, generatedEdges);
    setNodes(elkNodesAndEdges.nodes);
    setEdges(elkNodesAndEdges.edges);
 
    setIsGraphReady(true);
  };
 
  const applyElkLayout = async (nodes: Node[], edges: Edge[]) => {
    const elk = new ELK();
    const elkGraph = {
      id: "root",
      children: nodes.map((node) => ({
        id: node.id,
        width: 120,
        height: 50,
      })),
      edges: edges.map((edge) => ({
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
      })),
    };
 
    const layout = await elk.layout(elkGraph, {
      layoutOptions: {
        "elk.algorithm": "layered", // You can use "layered", "force", or other algorithms
        "elk.direction": "RIGHT", // Arrange graph from left to right
        "elk.layered.spacing.nodeNodeBetweenLayers": "300",
        "elk.layered.spacing.nodeNode": "150",
        "elk.spacing.componentComponent": "200",
        "elk.edgeRouting": "ORTHOGONAL", // Improves edge clarity
        "elk.layered.spacing.edgeNode": "75", // Space between edges and nodes
      },
    });
 
    const positionedNodes = (layout.children || []).map((node: any) => ({
      id: node.id,
      data: { label: node.id },
      position: { x: node.x, y: node.y },
      draggable: true,
    }));
 
    const positionedEdges = (layout.edges ?? []).map((edge: any) => {
      const originalEdge = edges.find((e) => e.id === edge.id);
      return {
        id: edge.id,
        source: edge.sources[0],
        target: edge.targets[0],
        ...originalEdge,
      };
    });
 
    return { nodes: positionedNodes, edges: positionedEdges };
  };
 
 
  const FlowWithCentering = ({ nodes, edges }: any) => {
    const { fitView } = useReactFlow();
 
    useEffect(() => {
      fitView({ padding: 0.5 });
    }, [nodes, edges, fitView]);
 
    return (
      <ReactFlow
      nodes={nodes}
      edges={edges}
      fitView
      fitViewOptions={{ padding: 0.5 }}
      nodesDraggable={true}
      onEdgeClick={handleEdgeClick}
      style={{ pointerEvents: "auto", height: "100%" }}
    >
        <Controls />
        <Background />
      </ReactFlow>
    );
  };
 
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <h2>Data Model Relationships (ERD)</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: "black", fontWeight: "bold" }}>{error}</p>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <p style={{ margin: 0, marginRight: "8px" }}>
              Select <strong>All</strong> to see the relationship table, or select individual tables to visualize an ERD.
            </p>
            <TooltipHost content="Use this dropdown to toggle between viewing the relationship table and ERD visualizations.">
              <IconButton
                iconProps={{ iconName: "Info" }}
                styles={{ root: { fontSize: 14 } }}
                ariaLabel="Info"
              />
            </TooltipHost>
          </div>
 
          <Dropdown
            placeholder="Select a table"
            options={tableOptions}
            onChange={handleTableChange}
            selectedKey={selectedTable}
            styles={{ dropdown: { width: 300, marginBottom: "15px" } }}
          />
 
          {selectedTable === "All" ? (
            <RelationshipTable data={relationshipData} />
          ) : (
            <>
              <p style={{ fontStyle: "italic", color: "#555", marginBottom: "10px", paddingBottom: '5px'}}>
                Note: Click on an edge in the ERD to view relationship details such as cardinality, source, and target columns.
              </p>
 
              <div style={{ display: "flex", height: "100%", gap: "6px" }}>
                {/* ReactFlow Container */}
                <div
                   style={{
                    flex: 1,
                    height: "calc(100vh - 300px)", // Adjust height
                    backgroundColor: "#f7f7f7",
                    border: "1px solid #ccc",
                    marginRight: "10px",
                  }}
                >
 
                  {isGraphReady && (
                    <ReactFlowProvider>
                              <FlowWithCentering
                                nodes={nodes}
                                edges={edges.map((edge) => ({
                                  ...edge,
                                  style: {
                                    ...edge.style,
                                    stroke: edge.id === selectedEdge?.id ? "#3c6fb2" : "#000",
                                    strokeWidth: edge.id === selectedEdge?.id ? 3 : 2,
                                  },
                                }))}
                              />
                    </ReactFlowProvider>
                  )}
                </div>
 
                {selectedEdge && (
                  <div
                    style={{
                      padding: "10px",
                      border: "1px solid #ccc",
                      backgroundColor: "#f9f9f9",
                      height:"250px",
                      marginRight:"10px",
                    }}
                  >
                    <h3>Selected Relationship Details</h3>
                    <p><strong style={{fontWeight: "500"}}>From Table:</strong> {selectedEdge.source}</p>
                    <p><strong style={{fontWeight: "500"}}>To Table:</strong> {selectedEdge.target}</p>
                    <p><strong style={{fontWeight: "500"}}>From Column:</strong> {selectedEdge.data?.fromColumn as string}</p>
                    <p><strong style={{fontWeight: "500"}}>To Column:</strong> {selectedEdge.data?.toColumn as string}</p>
                    <p><strong style={{fontWeight: "500"}}>Relationship Cardinality:</strong> {selectedEdge.data?.relationshipCardinality as string}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
 
export default DataModel;