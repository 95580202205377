import { useEffect, useRef, useState } from "react";
import { Constants } from "./helpers/Constants";
import "../Styles/Hub.css";
import "../Styles/scrollbar.css"
import axios from "axios";
import React from "react";
import MSLogo from "../Images/msftLogo_1.svg";
import MSText from "../Images/Mlogo.png";
import { IResponse } from "./Interfaces/Interfaces";
import protectedAxiosInstance from "./helpers/api";
import { useNavigate } from "react-router-dom";



interface props {
    role: string
    setTab: (tileId: string) => void
    editing: boolean
    handleOpenDialogue: (value: boolean) => void
}
const LeftNav: React.FC<props> = ({ role, setTab, editing, handleOpenDialogue }) => {
    const navDate = new Date().getFullYear();
    const initialWidth = document.body.offsetWidth * 0.25
    const [triggerRender, setTrigger] = useState<boolean>(true);
    const [navList, setNavList] = useState<any>();
    const [isShowReportCatalog, setIsShowReportCatalog] =
        useState<boolean>(false);
    const [pageNavList, setPageNavList] = useState<any>();
    const [reportLevelIsCollapsed, setReportLevelIsCollapsed] =
        useState<boolean>(false);
    const [currentGrandParent, setCurrentGrandParent] = useState<string>("");
    const [currentParent, setCurrentParent] = useState<string>("");
    const [currentChild, setCurrentChild] = useState<string>("");
    const [collapseNavbarIcons, setCollapseNavbarIcons] =
        useState<boolean>(false);
    const [reportsloaded, setReportsloaded] = useState<string[]>([]);
    const [showReportIcon, setShowReportIcon] = useState<string[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>("");
    const [horizontalNavList, setHorizontalNavList] = useState<any>([]);
    const [subpagenav, setSubpagenav] = useState<boolean>(false);
    const [powerbiLoaded, setPowerbiLoaded] = useState<string[]>([]);
    const [openedreport, setOpenedreport] = useState<string>("");
    const [width, setwidth] = useState(initialWidth)
    const [isResizing, setIsResizing] = useState(false);
    const [style, setStyle] = useState({})
    const navRef = useRef<HTMLDivElement>(null);
    const resizerRef = useRef<HTMLDivElement>(null);
    // const [role, setRole] = useState<string>("")
    const navigate = useNavigate();
    const [HomeClicked, setHomeClicked] = useState<boolean>(false);


    const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsResizing(true);
        const leftNavContainer = document.getElementsByClassName("leftNavContainer") as HTMLCollectionOf<HTMLElement>;
        const reportContainer = document.getElementsByClassName("report-container") as HTMLCollectionOf<HTMLElement>;
        // foreach leftnav comp
        console.log(leftNavContainer)
        if (reportContainer[0]) {
            reportContainer[0].style.pointerEvents = "none";
        }
    };

    const onMouseUp = (e: MouseEvent) => {
        setIsResizing(false);
        const leftNavContainer = document.getElementsByClassName("leftNavContainer") as HTMLCollectionOf<HTMLElement>;
        const reportContainer = document.getElementsByClassName("report-container") as HTMLCollectionOf<HTMLElement>;
        if (leftNavContainer[0]) {
            leftNavContainer[0].style.pointerEvents = "auto";
        }
        if (reportContainer[0]) {
            reportContainer[0].style.pointerEvents = "auto";
        }
    };

    const onMouseMove = (e: MouseEvent) => {
        if (isResizing) {
            const nav = document.getElementById("resizer")
            const navRect = nav!.getBoundingClientRect();
            const offsetX = e.clientX - navRect.left;
            const newWidth = width + offsetX
            const navDiv = document.getElementById('expandedNavContainer') as HTMLElement
            navDiv.style.width = `${newWidth}px`;
            setwidth(newWidth)
        }
    };

    const setBackGroundColor = () => {
        const url = window.location.href;
        if (url.includes("localhost")) {
            setStyle({ backgroundColor: "#526577" })
        }
        if (url.includes("dev")) {
            setStyle({ backgroundColor: "#526577" })
        }
        if (url.includes("uat")) {
            setStyle({ backgroundColor: "#4A786F" })
        }
        if (url.includes("prod") || url.includes("ftbi.microsoft.com")) {
            setStyle({ backgroundColor: "#5f6b6d" })
        }
    }

    const getHorizontalNavList = async () => {
        let tmpHorizontalList = []

        try {
            const response = await protectedAxiosInstance.get('/api/service/GetHorizontalTileList', {
                withCredentials: true,
            })
            tmpHorizontalList = JSON.parse(JSON.parse(response.data.Table[0].ListData))
            setHorizontalNavList(tmpHorizontalList)
        } catch (error) {
            console.error("Error", error)
        }
    }


    useEffect(() => {
        getNavList()
        getHorizontalNavList()
        setBackGroundColor()

    }, []);

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);

        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
    });

    const setTilesToBeCollapsed = (navlist: any) => {
        setShowReportIcon(Constants.LIST_OF_TILE_ID_TO_TO_BE_COLLAPSED);
        const params = new URLSearchParams(location.search)
        let tab

        if (params.has("parent"))
            tab = params.get("parent")

        if (tab != "ReportCatalog_SearchReport") {
            for (const tile in navlist) {
                const grandParent = navlist[tile].tileId
                if (navlist[tile].hasSubPages) {
                    const subNavList = navlist[tile].subPages
                    for (const subNav in subNavList) {
                        const parent = subNavList[subNav].tileId
                        if (parent === tab) {
                            setShowReportIcon([...showReportIcon, grandParent])
                            // setSelectedTab(parent)
                            setCurrentGrandParent(grandParent)
                            setCurrentParent(parent)
                        } else {
                            if (subNavList[subNav].hasSubPages) {
                                const LastLevList = subNavList[subNav].subPages
                                for (const lastLev in LastLevList) {
                                    const child = LastLevList[lastLev].tileId
                                    if (child === tab) {
                                        setShowReportIcon([...showReportIcon, grandParent, parent])
                                        setCurrentGrandParent(grandParent)
                                        setCurrentParent(parent)
                                        setCurrentChild(child)
                                        // setSelectedTab(child)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


    }

    const setSearchParams = (tileId: string) => {
        const horizontalNavlist = horizontalNavList
        if (horizontalNavlist[tileId] === undefined) {
            let goToURL = `/view?parent=${tileId}`
            navigate(goToURL)
            setTab(tileId)
            return
        }
        const tileObject = horizontalNavlist[tileId || ""][0];
        const goToURL = `/view?parent=${tileId}&tileId=${tileObject.tileId}`
        navigate(goToURL)
        setTab(tileId)
    }


    const getNavList = async () => {
        let navList = [];
        try {
            const response = await protectedAxiosInstance.get('/api/service/GetNavlist', {
                withCredentials: true,
            })
            navList = JSON.parse(JSON.parse(response.data.Table[0].ListData))
            setNavList(navList)
            setTilesToBeCollapsed(navList)
        } catch (error) {
            console.error("Error", error)
        }
    };

    const setTitle = (title: string) => {
        if (title == "Landing Page") {
            document.title = "FastTrack BI";
        } else {
            document.title = title;
        }
    };

    const showHideReportCatalog = () => {
        const tileId = "ReportCatalog_SearchReport"
        setSearchParams(tileId)
    }

    // const env: string = (window.location.host.includes("dev") || window.location.host.includes("localhost")) ? "dev" : "non-dev"; // WILL BE USED LATER IF NEEDED

    const showHideNavItemGrid = () => {
        setCurrentGrandParent("");
        setCurrentParent("");
        setCurrentChild("");

        // setShowReportIcon([])
    };

    const reportnavclicked = (tileId: string) => {
        if (getSubPageBoolenaOnLoad(tileId)) {
            setSubpagenav(true);
        }

        // updatePageNavDefaultTile(tileId, "", "");
        if (reportsloaded.indexOf(tileId) < 0) {
            setReportsloaded([...reportsloaded, tileId]);
        }
    };

    const toggleDropdown = (tileId: string) => {
        if (showReportIcon.indexOf(tileId) < 0) {
            openDropDown(tileId);
        } else if (showReportIcon.indexOf(tileId) >= 0) {
            closeDropDown(tileId);
        }
    };


    const openDropDown = (tileId: string) => {
        setShowReportIcon([...showReportIcon, tileId]);
    };

    const closeDropDown = (tileId: string) => {

        const tempShowIcon = showReportIcon;
        tempShowIcon.forEach((item: string, index: any) => {
            if (item === tileId) tempShowIcon.splice(index, 1);
        });
        setShowReportIcon(tempShowIcon)
        setTrigger(!triggerRender)
    };


    const getPageNavListOnLoad = (tileId: string) => {
        const navlist = navList;
        for (const nav in navlist) {
            if (navlist[nav].hasSubPages && navlist[nav].tileId == tileId) {
                return navlist[nav].subPages;
            }
        }
        return [];
    };
    const getSubPageNavListOnLoad = (titleId: string, SubtileId: string) => {
        const navlist = navList;
        for (const nav in navlist) {
            if (navlist[nav].hasSubPages && navlist[nav].tileId == titleId) {
                const subNavList = navlist[nav].subPages;
                for (const subNavListItem in subNavList) {
                    if (
                        subNavList[subNavListItem].hasSubPages &&
                        subNavList[subNavListItem].tileId == SubtileId
                    ) {
                        {
                            return subNavList[subNavListItem].subPages;
                        }
                    }
                }
            }
        }
        return [];
    };

    const getSubPageBoolenaOnLoad = (tileId: string) => {
        const navlist = navList;
        for (const nav in navlist) {
            if (navlist[nav].hasSubPages && navlist[nav].tileId == tileId) {
                return navlist[nav].hasSubPages;
            }
        }
        return false;
    };

    const getLastLevelPageBoolenaOnLoad = (
        titleId: string,
        SubtileId: string
    ) => {
        const navlist = navList;
        for (const nav in navlist) {
            if (navlist[nav].hasSubPages && navlist[nav].tileId == titleId) {
                const subNavList = navlist[nav].subPages;
                for (const subNavListItem in subNavList) {
                    if (
                        subNavList[subNavListItem].hasSubPages &&
                        subNavList[subNavListItem].tileId == SubtileId
                    ) {
                        {
                            return subNavList[subNavListItem].hasSubPages;
                        }
                    }
                }
            }
        }
        return false;
    };

    const pagenavclicked = (
        parentTileId: string,
        tileId: string,
        hasSubPages: boolean,
        clickEvent: any
    ) => {
        if (!hasSubPages) {
            if (editing) {
                handleOpenDialogue(true)
            }
            else
                setSearchParams(tileId)
        }
        // setCurrentParent(tileId)
        setSubpagenav(true);
        if (!editing)
            updatePageNavDefaultTile(parentTileId, tileId, "", true);
        clickEvent.stopPropagation();

    };

    const subpagenavclicked = (
        grandParentId: string,
        parentId: string,
        childId: string,
        clickEvent: React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
        if (!editing) {
            setSearchParams(childId)
        }
        else {
            handleOpenDialogue(true)
        }
        setSubpagenav(false);
        if (!editing)
            updatePageNavDefaultTile(grandParentId, parentId, childId, true);
        clickEvent.stopPropagation();
        setIsShowReportCatalog(false);
    }


    const updatePageNavDefaultTile = (
        tileId: string,
        SubtileId: string,
        tileclicked: string,
        isclicked: boolean = false
    ) => {
        let clickedItem = "";
        let reportOpened = ""
        setOpenedreport("");
        // setUsageTracking("");
        let urlTab = false;
        const params = new URLSearchParams(location.search);
        if (params.has("parent") && params.get("parent")!.length > 0) {
            setSelectedTab(params.get("parent")!)
        }


        if (tileclicked != null && tileclicked.length > 0) {
            const navlist = navList;
            // getHorizontalNavList(tileclicked);
            setCurrentGrandParent(tileId);
            setCurrentParent(SubtileId);
            setCurrentChild(tileclicked);
            let pageNavList = [];
            for (const nav in navlist) {
                if (navlist[nav].tileId == tileId && navlist[nav].hasSubPages) {
                    Constants.NavLevel = navlist[nav].tileId;
                    clickedItem += navlist[nav].tileText.replace(/\//, "&") + "/";
                    // setUsageTracking(clickedItem);
                    setOpenedreport(navlist[nav].tileId + "#|#" + navlist[nav].tileText);
                    navlist[nav].isDefault = true;
                    const subNavList = navlist[nav].subPages;
                    pageNavList = subNavList;
                    for (const subNavListItem in subNavList) {
                        if (
                            subNavList[subNavListItem].hasSubPages &&
                            subNavList[subNavListItem].tileId == SubtileId
                        ) {
                            subNavList[subNavListItem].isDefault = true;
                            Constants.ReportLevel = subNavList[subNavListItem].tileId;
                            clickedItem +=
                                subNavList[subNavListItem].tileText.replace(/\//, "&") + "/";
                            // setUsageTracking(clickedItem);
                            setOpenedreport(
                                subNavList[subNavListItem].tileId +
                                "#|#" +
                                subNavList[subNavListItem].tileText
                            );
                            pageNavList = subNavList[subNavListItem].subPages;
                        } else {
                            subNavList[subNavListItem].isDefault = false;
                        }
                    }
                } else {
                    navlist[nav].isDefault = false;
                }
            }
            for (const nav in pageNavList) {
                if (pageNavList[nav].tileId == tileclicked) {
                    pageNavList[nav].isDefault = true;
                    setTitle(pageNavList[nav].tileText);
                    clickedItem += pageNavList[nav].tileText.replace(/\//, "&") + "/";
                    // setUsageTracking(clickedItem);
                    setOpenedreport(
                        pageNavList[nav].tileId + "#|#" + pageNavList[nav].tileText
                    );
                    reportOpened = pageNavList[nav].tileId + "#|#" + pageNavList[nav].tileText
                    if (reportsloaded.indexOf(tileId) < 0) {
                        setReportsloaded([...reportsloaded, tileId]);
                    }
                    if (!horizontalNavList || horizontalNavList.length < 0) {
                        for (const tab in horizontalNavList) {
                            if (horizontalNavList[tab].tileId == selectedTab) {
                                horizontalNavList[tab].isDefault = true;
                                Constants.REPORT_SELECTED_SECTION =
                                    horizontalNavList[tab].sectionId;
                                if (horizontalNavList[tab].isTab) {
                                    if (
                                        powerbiLoaded.indexOf(horizontalNavList[tab].parentId) < 0
                                    ) {
                                        setPowerbiLoaded([
                                            ...powerbiLoaded,
                                            horizontalNavList[tab].parentId,
                                        ]);
                                    } else {
                                        setPowerbiLoaded([
                                            ...powerbiLoaded,
                                            horizontalNavList[tab].tileId,
                                        ]);
                                    }
                                }
                            } else {
                                horizontalNavList[tab].isDefault = false;
                            }
                        }
                        if (reportsloaded.indexOf(tileId) < 0) {
                            setReportsloaded([...reportsloaded, tileId]);
                        }
                        if (urlTab) {
                            Constants.TabLevel = selectedTab;
                            // Common.setQueryParameters(selectedTab, "");
                        } else {
                            setSelectedTab(horizontalNavList[0].tileId);
                            Constants.TabLevel = selectedTab;
                        }
                    } else {
                        lastLevelNavigation(tileclicked);
                    }

                } else {
                    pageNavList[nav].isDefault = false;
                }
            }
        } else if (SubtileId != null) {
            const navlist = navList;
            if (!getLastLevelPageBoolenaOnLoad(tileId, SubtileId)) {
                setCurrentGrandParent(tileId);
                setCurrentParent(SubtileId);
                setCurrentChild(tileclicked);
                Constants.TabLevel = "";
                // getHorizontalNavList(SubtileId);
                for (const nav in navlist) {
                    if (
                        navlist[nav].tileId == tileId &&
                        navlist[nav].hasSubPages == true
                    ) {
                        // pageNavList = navList[nav].subPages;
                        setPageNavList(navlist[nav].subPages);
                        navlist[nav].isDefault = true;
                        Constants.NavLevel = navlist[nav].tileId;
                        clickedItem += navlist[nav].tileText.replace(/\//, "&") + "/";
                        // setUsageTracking(clickedItem);
                        setOpenedreport(
                            navlist[nav].tileId + "#|#" + navlist[nav].tileText
                        );
                    } else {
                        navlist[nav].isDefault = false;
                    }
                }
                for (const nav in pageNavList) {
                    if (pageNavList[nav].tileId == SubtileId) {
                        pageNavList[nav].isDefault = true;
                        Constants.ReportLevel = pageNavList[nav].tileId;
                        setTitle(pageNavList[nav].tileText);
                        clickedItem += pageNavList[nav].tileText.replace(/\//, "&") + "/";
                        // setUsageTracking(clickedItem);
                        setOpenedreport(
                            pageNavList[nav].tileId + "#|#" + pageNavList[nav].tileText
                        );
                        if (!pageNavList[nav].hasSubPages) {
                            if (reportsloaded.indexOf(tileId) < 0) {
                                setReportsloaded([...reportsloaded, tileId]);
                            }
                        }
                    } else {
                        pageNavList[nav].isDefault = false;
                    }
                }
            }
        } else {
            if (!getSubPageBoolenaOnLoad(tileId)) {
                // getHorizontalNavList(tileId);
                const navlist = navList;
                setCurrentGrandParent(tileId);
                setCurrentParent(SubtileId);
                setCurrentChild(tileclicked);
                Constants.TabLevel = "";
                Constants.ReportLevel = "";
                // getHorizontalNavList(tileId);
                for (const nav in navlist) {
                    if (navlist[nav].tileId == tileId) {
                        navlist[nav].isDefault = true;
                        Constants.NavLevel = navlist[nav].tileId;
                        setTitle(navlist[nav].tileText);
                        clickedItem += navlist[nav].tileText.replace(/\//, "&") + "/";
                        // setUsageTracking(clickedItem);
                        setOpenedreport(
                            navlist[nav].tileId + "#|#" + navlist[nav].tileText
                        );
                        if (reportsloaded.indexOf(tileId) < 0) {
                            setReportsloaded([...reportsloaded, tileId]);
                        }
                        lastLevelNavigation(tileId);
                    } else {
                        navlist[nav].isDefault = false;
                    }
                }
            }
        }
    };
    const checkArrowDirection = (tileId: string): boolean => {
        return showReportIcon.indexOf(tileId) >= 0;
    };

    const lastLevelNavigation = (selectedtab: string) => {
        setSelectedTab(selectedtab);
    };


    const toggleAdminPortal = () => {
        navigate('/admin');
    }

    return (
        <>
            {!reportLevelIsCollapsed && <div
                style={style}
                className="reportNav expandedNavContainer"
                ref={navRef}
                id="expandedNavContainer"

            >
                <div
                    ref={resizerRef}
                    onMouseDown={onMouseDown}
                    className="resizer"
                    id="resizer" />
                <span
                    className={`reportcollapseicon ${!collapseNavbarIcons ? "unCollapsedIcons" : ""
                        } ${collapseNavbarIcons ? "collapsedIcons" : ""}`}
                    onClick={() => {
                        if (!editing) {
                            navigate('/');
                        }
                        else {
                            handleOpenDialogue(true)
                        }
                    }}
                >
                    <span className="reportnavicon" title="Navigation"></span>
                </span>

                <div className="leftNavContainer">
                    <div className="cursorPointer navText" onClick={() => {
                        setHomeClicked(true);
                        if (!editing) {
                            setTab("")
                            navigate('/');
                        } else {
                            handleOpenDialogue(true)
                        }
                    }}>
                        <span className="ReportNavHead cursorPointer" title="Home">
                            Home
                        </span>
                    </div>
                    <hr className="navseparatorblue" />
                    <div className="Reports_ReportsCatalog">
                        <ul className="reportNavul">
                            {navList && navList.length > 0 && navList?.map((opt: any) => (
                                <li
                                    key={opt.tileId}
                                    className={`reportNavli ${opt.isDefault && opt.tileId === currentGrandParent
                                        ? "categorySelected"
                                        : ""
                                        }`}
                                    title={opt.tileText}
                                    id={`${opt.tileId}_2nd_Report`}
                                    onClick={() => {

                                        reportnavclicked(opt.tileId);
                                        toggleDropdown(opt.tileId);

                                    }

                                    }
                                    tabIndex={0}
                                >
                                    <span>
                                        <span
                                            id={`${opt.tileId}_1stlayer_Report`}
                                            className="reportNavliText"
                                            title={opt.tileText}
                                        >
                                            {opt.tileText}
                                        </span>
                                        <span
                                            id={`${opt.tileId}_arrow_Report`}
                                            className={`${opt.hasSubPages && !checkArrowDirection(opt.tileId)
                                                ? "reportshowicon"
                                                : ""
                                                } ${!opt.hasSubPages ? "noSubPages" : ""} ${checkArrowDirection(opt.tileId) ? "reporthideicon" : ""
                                                }`}
                                        ></span>
                                    </span>
                                    {checkArrowDirection(opt.tileId) &&
                                        <ul
                                            id={`${opt.tileId}_2ndLayerMenu_Report`}
                                            className={`pageNavul ${opt.hasSubPages && checkArrowDirection(opt.tileId)
                                                ? "showList"
                                                : ""
                                                } ${!checkArrowDirection(opt.tileId) ? "hideList" : ""}`}
                                        >
                                            {getPageNavListOnLoad(opt.tileId).map((hpt: any) => (
                                                <li
                                                    key={hpt.tileId}
                                                    className={`pageNavli ${hpt.isDefault &&
                                                        hpt.hasSubPages &&
                                                        currentParent === hpt.tileId
                                                        ? "subCategorySelected"
                                                        : ""
                                                        } ${!hpt.hasSubPages && !HomeClicked &&
                                                            currentParent === hpt.tileId
                                                            ? "pageSelected"
                                                            : ""
                                                        }`}
                                                    title={hpt.tileText}
                                                    onClick={(e) => {
                                                        setHomeClicked(false);
                                                        pagenavclicked(
                                                            opt.tileId,
                                                            hpt.tileId,
                                                            hpt.hasSubPages,
                                                            e
                                                        );
                                                        toggleDropdown(hpt.tileId);


                                                    }}
                                                    // add enter key press event
                                                    onKeyDown={(e) => {
                                                        e.code === "13" &&
                                                            pagenavclicked(
                                                                opt.tileId,
                                                                hpt.tileId,
                                                                hpt.hasSubPages,
                                                                e
                                                            );
                                                        toggleDropdown(hpt.tileId);
                                                    }}
                                                    tabIndex={0}
                                                >
                                                    <span>
                                                        <span className="pageNavliText" title={hpt.tileText}>
                                                            {hpt.tileText}
                                                        </span>
                                                        <span
                                                            id={`${hpt.tileId}_arrow_Report`}
                                                            className={`${hpt.hasSubPages &&
                                                                !checkArrowDirection(hpt.tileId)
                                                                ? "reportshowicon"
                                                                : ""
                                                                } ${!hpt.hasSubPages ? "noSubPages" : ""} ${checkArrowDirection(hpt.tileId)
                                                                    ? "reporthideicon"
                                                                    : ""
                                                                }`}
                                                        ></span>
                                                    </span>
                                                    <ul
                                                        id={`${hpt.tileId}_3rdLayerMenu_Report`}
                                                        className={`lastLevelPageNavul ${checkArrowDirection(hpt.tileId) ? "showList" : ""
                                                            } ${!checkArrowDirection(hpt.tileId) ? "hideList" : ""
                                                            }`}
                                                    >
                                                        {getSubPageNavListOnLoad(opt.tileId, hpt.tileId).map(
                                                            (shpt: any) => (
                                                                <li
                                                                    key={shpt.tileId}
                                                                    className={`lastLevelPageNavli ${!HomeClicked && currentChild === shpt.tileId
                                                                        ? "pageSelected"
                                                                        : ""
                                                                        }`}
                                                                    id={`${hpt.tileId}_${shpt.tileId}_Report`}
                                                                    title={shpt.tileText}
                                                                    tabIndex={0}
                                                                    onClick={(e) => {
                                                                        setHomeClicked(false);
                                                                        subpagenavclicked(
                                                                            opt.tileId,
                                                                            hpt.tileId,
                                                                            shpt.tileId,
                                                                            e
                                                                        );

                                                                    }}
                                                                >
                                                                    {shpt.tileText}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>}
                                    <hr
                                        className="navseparatorblue"
                                        style={{
                                            marginLeft: "-31px",
                                            marginTop: "5px",
                                            marginBottom: "0px",
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>

                        {/* To Do this later(temporarirly commented) */}
                        <div
                            className="ReportCatalog cursorPointer"
                            onClick={() => {
                                if (!editing) {
                                    showHideReportCatalog();
                                    showHideNavItemGrid()
                                }
                                else {
                                    handleOpenDialogue(true)
                                }
                            }}
                            title="Report Catalog"
                        >
                            <span className="reportcatalogicon unCollapsedIcons"></span>
                            <span
                                className={`ReportNavHead ${isShowReportCatalog ? "reportCatalogSelected" : ""
                                    }`}
                                title="Report Catalog"
                            >
                                Report Catalog
                            </span>
                        </div>
                        <hr
                            className="navseparatorblue"
                        />

                        {(role === "Admin" || role === "SuperAdmin") && <>
                            <div className="spacing">
                                <span
                                    className={`reportNavli reportNavliText`}
                                    title="Admin"
                                    onClick={() => {
                                        toggleAdminPortal()
                                    }}

                                >
                                    Admin
                                </span>
                            </div>
                            <hr style={{ marginTop: "3px" }}
                                className="navseparatorblue"
                            />
                        </>}
                    </div>
                    <div className="dataProtectionNoticefooter">
                        <div className="collapseLeft">

                            <i className="ms-Icon ms-Icon--DoubleChevronLeft8 white-icon moveLeft" aria-hidden="true"
                                onClick={() => {
                                    setCollapseNavbarIcons(!collapseNavbarIcons)
                                    setReportLevelIsCollapsed(!reportLevelIsCollapsed)
                                }}></i>

                        </div>
                        <hr
                            className="navseparatorblue lastnav"
                        />
                        <div className="dataProtectionNoticecontainer">
                            <div className="MSLogoContainer">
                                <a
                                    className="dataNoticetext"
                                    href={Constants.MICROSOFT_LINK}
                                    target="_blank"
                                >
                                    MS Data Protection Notice
                                </a>
                                <div className="internalUseOnly">For Internal Use Only</div>
                            </div>
                            <div className="dataProtectionNotice">
                                <div className="MSOriginalFooter">
                                    <img
                                        title="mslogo_bottom"
                                        id="mslogo_bottom_Report"
                                        src={MSText}
                                        alt="mslogo_bottom"
                                    />
                                    <img
                                        title="mstext_bottom"
                                        id="mstext_bottom_Report"
                                        src={MSLogo}
                                        alt="mstext_bottom"
                                    />

                                </div>
                                <div className="msxInsightsSprite msLogoImage copyrightcss">
                                    &copy; Microsoft {navDate}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>}
            {reportLevelIsCollapsed && <div
                style={style}
                className="reportNav collapsedNavContainer"
            >
                <span
                    className={`reportcollapseicon ${!collapseNavbarIcons ? "unCollapsedIcons" : ""
                        } ${collapseNavbarIcons ? "collapsedIcons" : ""}`}
                    onClick={() => {
                        if (!editing)
                            navigate('/');
                        else {
                            handleOpenDialogue(true)
                        }
                    }}
                >
                    <span className="reportnavicon" title="Navigation" style={{
                        height: '17px',
                    }}></span>
                </span>
                <div className="collapseRight ">
                    <span>
                        <i className="ms-Icon ms-Icon--DoubleChevronRight8 white-icon moveRight" aria-hidden="true" onClick={() => {
                            setCollapseNavbarIcons(!collapseNavbarIcons)
                            setReportLevelIsCollapsed(!reportLevelIsCollapsed)
                        }}></i>
                    </span>
                </div>
            </div>}
        </>
    );
};

export default LeftNav;


