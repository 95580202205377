import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "./OCPLanding.css"; // Import global styles
import newsletterImg from "../../Images/img/newsletter.png";
import trainImg from "../../Images/img/train.png";
import newspaperIcon from "../../Images/img/newslettericon.png";
import trainingIcon from "../../Images/img/trainingicon.png";
import { Constants } from "../helpers/Constants";
import Loading from "../../Images/Loading.gif";
import { useNavigate } from "react-router-dom";
import playSmallbtnicn from "../../Images/img/playSmallbtnicn.png";

interface props {
  isLandingPageDataLoaded: boolean;
  latestNewLetterLinks: any[];
}

const LeftComponent: React.FC<props> = ({
  isLandingPageDataLoaded,
  latestNewLetterLinks,
}) => {
  return (
    <Col style={{ paddingLeft: "0", paddingRight: "10.5px" }}>
      <Card
        border="light"
        style={{ height: "100%", padding: "1.4rem" }}
        className="training"
      >
        <h5>
          <img src={playSmallbtnicn} alt="Play Icon" className="play-icon" />
          New FastTrack BI Portal Demo
        </h5>
        <div style={{height: "100%", width: "100%"}}>
          <div style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: "0",
            overflow: "hidden"
          }}>
            <iframe 
              src="https://microsoft.sharepoint.com/sites/FastTrackProductTeam/_layouts/15/embed.aspx?UniqueId=0f495841-3f98-48a4-a492-4a35203c3714&embed=%7B%22hvm%22%3Atrue%2C%22ust%22%3Atrue%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
              width="640"
              height="360"
              frameBorder="0"
              scrolling="no"
              allowFullScreen
              title="FTBI Portal Demo.mp4"
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: "100%",
                width: "100%"
              }}
            />
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default LeftComponent;
