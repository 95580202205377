import React, { useState } from 'react';
import {
  DetailsList,
  IColumn,
  buildColumns,
  SelectionMode,
  IDetailsListStyles,
  IDetailsColumnStyles,
} from '@fluentui/react/lib/DetailsList';
 
// Define props for the table component
interface RelationshipTableProps {
    data: { [key: string]: any }[];
}
 
const RelationshipTable: React.FC<RelationshipTableProps> = ({ data }) => {
  const [sortedData, setSortedData] = useState<any[]>(data);
  const [columns, setColumns] = useState<IColumn[]>(
    buildColumns(data, false).map((column) => ({
      ...column,
      name: column.fieldName?.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase() || '',
      isSorted: false,
      isSortedDescending: false,
      showSortIconWhenUnsorted: true, // Ensure the sort icon is always visible
    }))
  );
 
  const onColumnClick = (
    event: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const isSortedDescending = !column.isSortedDescending;
    const sortedItems = [...sortedData].sort((a, b) => {
      const key = column.fieldName!;
      if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
      if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
      return 0;
    });
 
    setSortedData(sortedItems);
 
    const updatedColumns = columns.map((col) => ({
      ...col,
      isSorted: col.key === column.key,
      isSortedDescending: col.key === column.key ? isSortedDescending : false,
    }));
    setColumns(updatedColumns);
  };
 
  return (
    <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
      <DetailsList
      styles={detailsListStyles}
      items={sortedData}
      columns={columns.map((column) => ({
        ...column,
        onColumnClick,
        styles: columnHeaderStyles, // Add columnHeaderStyles to each column
      }))}
      setKey="set"
      isHeaderVisible={true}
      selectionMode={SelectionMode.none}
      />
    </div>
  );
};
 
const detailsListStyles: Partial<IDetailsListStyles> = {
  headerWrapper: {
    marginTop: "-12px",
    selectors: {
      ".ms-DetailsHeader": {
        backgroundColor: "#505050",
      },
      ".ms-DetailsHeader-cellName": {
        color: "white",
      },
      ".ms-DetailsHeader-cell:hover": {
        backgroundColor: "#505050",
      },
    },
  },
};

const columnHeaderStyles: Partial<IDetailsColumnStyles> = {
  sortIcon: {
   color: 'white',
 },
};

export default RelationshipTable;